/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useRef, useState } from "react";
import "../../assets/css/EditProfile.css";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Collapse,
  Table,
} from "reactstrap";
// import "../../assets/css/toast.css";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import Cards from "@material-tailwind/react/Card";
import Button from "react-bootstrap/Button";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import EditHeader from "../../components/Headers/EditHeader";
import Paragraph from "@material-tailwind/react/Paragraph";
import H6 from "@material-tailwind/react/Heading6";
// import "../../assets/css/toast.css";
import dashboardImage from "../../assets/carousel/new_admin_dashboard.jpeg";

import {
  applyCoupoun,
  changeCurrentPassword,
  deleteNormalAdmin,
  disableTwoFA,
  editUserData,
  findOrganizationDetails,
  getUser,
  getUserInfo,
  hashPassword,
  logout,
  reinviteAdminUser,
  resendOtp,
  saveProfilePicture,
  sendOtpToDisable,
  sendOtpToEnable,
  sendOtpToNewEmail,
  sendOtpToUpdate,
  setupTwoFA,
  updateAdminPermissions,
  verifiedOtp,
} from "../../network/ApiAxios";
import { ThreeDots } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { updateUserProfile } from "components/redux/dataaction";
import { useSelector } from "react-redux";
import Message from "./Message";
import { useHistory } from "react-router";
import { toast, ToastContainer, Zoom } from "react-toastify";
import OTP from "./Otp";
import { Textarea } from "@material-tailwind/react";
import { getAllAdminsAction } from "components/redux/adminActions";
import ToggleSwitch from "./ToggleSwitch";
import { sha256 } from "js-sha256";
import subs from "../../assets/img/subscription/subs1.svg";
import { FaEdit } from "react-icons/fa";
import { AiOutlineMessage, AiTwotoneCloud } from "react-icons/ai";
import { getDataAction } from "components/redux/dataaction";
import { savePaymentInfo } from "components/redux/adminActions";
import customToast from "./ToastComponent";
import ValidatedInput from "functions/ValidatedInput";
import { FcInvite, FcSettings } from "react-icons/fc";
import { IoCheckmarkCircle, IoCloseCircleOutline } from "react-icons/io5";
import { Checkbox, FormControlLabel, Tooltip } from "@material-ui/core";
import { MdCancel } from "react-icons/md";
import { findExpiryDate } from "functions/timeConversion";
import { getPaymentDetails } from "components/redux/adminActions";
import { AUTH_ROUTE_PATHS } from "routes-constants";
import { ADMIN_ROUTE_PATHS } from "routes-constants";

const EditProfile = (props) => {
  let user = JSON.parse(localStorage.getItem("user"));
  const expiryDate = JSON.parse(localStorage.getItem("expiryDate"));
  const orgDetail = JSON.parse(localStorage.getItem("keys"));
  let userId = JSON.parse(localStorage.getItem("user"))?._id;
  let AdminEmail = JSON.parse(localStorage.getItem("user"))?.email;
  let orgTokenLS = JSON.parse(localStorage.getItem("user"))?.orgToken;
  const isSuperAdmin = JSON.parse(localStorage.getItem("user"))?.isSuperAdmin;
  const { whichPlanSubscribed, amountReceived } = useSelector(
    (state) => state.setPaymentInfoReducer
  );
  // let p = JSON.parse(localStorage.getItem("user"))?.password;
  let userinfo;

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [manageEmail, setManageEmail] = useState("");
  const [disable, setDisable] = useState(false);
  const [adminModal, setAdminModal] = useState(false);
  const [terms, setTerms] = useState(false);
  const [adminPermissions, setAdminPermissions] = useState({
    delete: false,
    wipe: false,
    FSL: false,
    groupSettings: false,
    fileSharingPrevention: false,
  });
  const [existingAdminPermissions, setExistingAdminPermissions] = useState({
    delete: false,
    wipe: false,
    FSL: false,
    groupSettings: false,
    fileSharingPrevention: false,
  });
  const newAdmin = () => setAdminModal(!adminModal);
  // const [password, setPassword] = useState("");
  const [password, setPassword] = useState("");
  const [deleteAdminUser, setDeleteAdminUser] = useState(false);
  const [isToggled, setIsToggled] = useState(
    JSON.parse(localStorage.getItem("twoFa"))
      ? JSON.parse(localStorage.getItem("twoFa"))
      : "false"
  );
  const [deletingAdmin, setDeletingAdmin] = useState(null);
  const [deleteAdminModal, setDeleteAdminModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isAdminOpen, setIsAdminOpen] = useState(false);
  const [security, setSecurity] = useState(false);
  const [phone, setPhone] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [orgtoken, setOrgToken] = useState("");
  const [isTestUser, setIsTestUser] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [userinfores, setUserInfo] = useState(null);
  const [edit, setEdit] = useState(false);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const getAdminReducerDetails = useSelector(
    (state) => state.getAdminReducer.adminData
  );
  const calcDays = useSelector((state) => state.getDataReducer.data);
  const toggleDeleteAdmin = () => setDeleteAdminModal(!deleteAdminModal);

  const [modal, setModal] = useState(false);
  const [modalOTP, setModalOTP] = useState(false);
  const [updateDetails, setUpdateDetails] = useState(false);
  const [modalChangeOTP, setModalChangeOTP] = useState(false);
  const [twoFaOtp, setTwoFaOtp] = useState(false);
  const [disableTwoFaOtp, setDisableTwoFaOtp] = useState(false);
  const [image, setImage] = useState("");
  const [decodedImage, setDecodedImage] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [currPassword, setCurrPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [height, setHeight] = useState(0);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isPasswordMatch, setIsPasswordMatch] = useState(true);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] =
    useState("");
  const [OTP, setOTP] = useState(""); // otp state for edit profile
  const [OTP2Fa, setOTP2Fa] = useState(""); // otp state for enabling and disabling 2 fa
  const [OTPChangePassword, setOtpChangePassword] = useState(""); // otp for changing password
  const [loadChangePassword, setLoadChangePassword] = useState(false); // state for disabling change passwd btn
  const [load2Fa, setLoad2Fa] = useState(false); // state for enabling/disabling 2 fa
  const [loadUpdateProfile, setLoadUpdateProfile] = useState(false); // state for updating profile

  const ref = useRef(null);

  useEffect(() => {
    if (ref.current?.clientHeight) {
      setTimeout(() => {
        setHeight(ref.current?.clientHeight);
      }, 1000);
    }
  }, [isAdminOpen, getAdminReducerDetails?.data]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setError("");
    }, 3000);

    return () => clearTimeout(timeout);
  }, [error]);

  useEffect(() => {
    dispatch(getPaymentDetails(orgDetail.org_token));
  }, []);

  const toggle = () => setModal(!modal);
  const toggleOTP = () => setModalOTP(!modalOTP);
  const toggleChangeOTP = () => setModalOTP(!modalChangeOTP);
  const toggleTwoFA = () => setTwoFaOtp(!twoFaOtp);
  const toggleDisableTwoFA = () => setDisableTwoFaOtp(!disableTwoFaOtp);
  const toggleUpdateDetails = () => {
    setUpdateDetails(!updateDetails);
    setEmail(JSON.parse(localStorage.getItem("user"))?.email);
    setName(JSON.parse(localStorage.getItem("user"))?.name);
    setPhone(JSON.parse(localStorage.getItem("keys"))?.org_name);
    setOrgToken(JSON.parse(localStorage.getItem("keys"))?.org_token);
  };

  const handleChange = (event) => {
    const { name, checked } = event.target;
    // console.log(name, checked, "e");
    // console.log(name, checked);
    setAdminPermissions((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  // NOT USING CURRENTLY BUT WILL BE USED, IF WE PURCHASE THE API
  const validatePhoneNumber = (phone) => {
    return phone.match(
      /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i
    );
  };

  const phoneValidation = () => {
    const regex = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;
    return !(!phone || regex.test(phone) === false);
  };
  useEffect(() => {
    // otherFunc();

    if (JSON.parse(localStorage.getItem("user"))?.email === "test@test.com") {
      setIsTestUser(true);
    }

    // console.log("USERINFO", userinfo, userinfores);
    setEmail(JSON.parse(localStorage.getItem("user"))?.email);
    setName(JSON.parse(localStorage.getItem("user"))?.name);
    setPhone(JSON.parse(localStorage.getItem("keys"))?.org_name);
    setOrgToken(JSON.parse(localStorage.getItem("keys"))?.org_token);

    // if (userinfores) {
    // 	console.log(userinfores);
    // 	let userinfo = userinfores?.data?.userinfo[0];
    // 	if (userinfo) {
    // 		setName(userinfo.name);
    // 		setEmail(userinfo.email);
    // 		setPhone(userinfo.number);
    // 		setOrgToken(userinfo.orgToken);
    // 		setPassword(userinfo.password);
    // 	}
    // }
  }, []); // * userinfores likho - jaadu++

  useEffect(() => {
    dispatch(getAllAdminsAction(orgTokenLS));
    dispatch(getDataAction());
    dispatch({ type: "SET_PAYMENT_INFO_REQUEST" });
  }, []);

  useEffect(() => {
    const runAsync = async () => {
      const res = await getUser();
      if (res.data.success === false) {
        localStorage.clear();
        props.history.push(`${AUTH_ROUTE_PATHS.AUTH_LAYOUT}${AUTH_ROUTE_PATHS.LOGIN}`);
      }
    };
    runAsync();
  }, [deletingAdmin]);

  const handleChangeName = (e) => {
    setName(e.target.value);
  };
  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleChangePhone = (e) => {
    setPhone(e.target.value);
  };

  const editUser = async () => {
    try {
      setLoadUpdateProfile(true);
      const { data } = await verifiedOtp(user._id, OTP);
      if (data.success === true) {
        const response = await editUserData(user._id, name);
        if (response.data.success === true) {
          customToast(
            true,
            "",
            "",
            "Profile Updated!",
            "Your profile has been updated, kindly re-login to take the effect.",
            "Go to Dashboard",
            `${ADMIN_ROUTE_PATHS.ADMIN_LAYOUT}${ADMIN_ROUTE_PATHS.INDEX}`,
            ""
          );
          setLoadUpdateProfile(false);
          await logOut();
        } else {
          customToast(
            "",
            true,
            "",
            "Email Already Exists",
            "Please enter different email to proceed.",
            "Go to Dashboard",
            `${ADMIN_ROUTE_PATHS.ADMIN_LAYOUT}${ADMIN_ROUTE_PATHS.INDEX}`,
            ""
          );
          setLoadUpdateProfile(false);
          setModalOTP(false);
        }
      } else {
        customToast(
          "",
          true,
          "",
          "Enter correct OTP",
          `${data.message}`,
          "Go to Dashboard",
          `${ADMIN_ROUTE_PATHS.ADMIN_LAYOUT}${ADMIN_ROUTE_PATHS.INDEX}`,
        );
        setLoadUpdateProfile(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 429) {
        customToast(
          "",
          true,
          "",
          "Too many wrong attempts",
          "Number of attempts exceeded",
          "Try Again after 10 minutes",
          ""
        );
      }
    }
  };

  const setup2Fa = async () => {
    try {
      const { data } = await verifiedOtp(userId, OTP2Fa);
      if (data.success === true) {
        setLoad2Fa(true);
        const { data } = await setupTwoFA(userId);
        localStorage.setItem("twoFa", "true");
        if (data.success === true) {
          toggleTwoFA();
          setOTP2Fa("");
          setLoad2Fa(false);
          window.location.reload();
        } else {
          customToast(
            "",
            true,
            "",
            "Some error occurred",
            `${data.msg}`,
            "Go to Dashboard",
            `${ADMIN_ROUTE_PATHS.ADMIN_LAYOUT}${ADMIN_ROUTE_PATHS.INDEX}`,
            ""
          );
          setOTP2Fa("");
          setLoad2Fa(false);
          setTwoFaOtp(false);
        }
      } else {
        customToast(
          "",
          true,
          "",
          "Enter correct OTP",
          `${data.message}`,
          "Go to Dashboard",
          `${ADMIN_ROUTE_PATHS.ADMIN_LAYOUT}${ADMIN_ROUTE_PATHS.INDEX}`
        );
        setOTP2Fa("");
        setLoad2Fa(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 429) {
        customToast(
          "",
          true,
          "",
          "Too many wrong attempts",
          "Number of attempts exceeded",
          "Try Again after 10 minutes",
          ""
        );
      }
    }
  };

  const disable2Fa = async () => {
    try {
      const { data } = await verifiedOtp(userId, OTP2Fa);
      if (data.success === true) {
        setLoad2Fa(true);
        const { data } = await disableTwoFA(userId);
        localStorage.setItem("twoFa", "false");
        if (data.success === true) {
          toggleDisableTwoFA();
          setOTP2Fa("");
          setLoad2Fa(false);
        } else {
          customToast(
            "",
            true,
            "",
            "Some error occurred",
            `${data.msg}`,
            "Go to Dashboard",
            `${ADMIN_ROUTE_PATHS.ADMIN_LAYOUT}${ADMIN_ROUTE_PATHS.INDEX}`,
            ""
          );
          setTwoFaOtp(false);
          setOTP2Fa("");
          setLoad2Fa(false);
        }
      } else {
        customToast(
          "",
          true,
          "",
          "Enter correct OTP",
          `${data.message}`,
          "Go to Dashboard",
          `${ADMIN_ROUTE_PATHS.ADMIN_LAYOUT}${ADMIN_ROUTE_PATHS.INDEX}`,
        );
        setOTP2Fa("");
        setLoad2Fa(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 429) {
        customToast(
          "",
          true,
          "",
          "Too many wrong attempts",
          "Number of attempts exceeded",
          "Try Again after 10 minutes",
          ""
        );
      }
    }
  };

  const logOut = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      const response = await logout(token);
      const { data } = response;
      dispatch({ type: "LOGOUT" });

      localStorage.clear();
      props.history.push(`${AUTH_ROUTE_PATHS.AUTH_LAYOUT}${AUTH_ROUTE_PATHS.LOGIN}`);
    }
  };

  const verify = async () => {
    try {
      // const isPhoneValid = phoneValidation();
      // if (!validateEmail(email)) {
      //   customToast(
      //     "",
      //     true,
      //     "",
      //     "Enter valid email",
      //     `Entered email is invalid`,
      //     "Go to Dashboard",
      //     "/admin/dashboard",
      //     ""
      //   );
      // } else if (
      //   isPhoneValid === false ||
      //   phone.length > 15 ||
      //   phone.length < 7
      // ) {
      //   customToast(
      //     "",
      //     true,
      //     "",
      //     "Enter valid phone number",
      //     `Entered contact number is invalid`,
      //     "Go to Dashboard",
      //     "/admin/dashboard",
      //     ""
      //   );
      // } else {

      if (name) {
        const res = await sendOtpToUpdate(email, userId);
        if (res.data.success == true) {
          setModalOTP(true);
          customToast(
            true,
            "",
            "",
            "OTP sent",
            "Kindly check your registered mail address and enter the OTP",
            "Check Email",
            ""
          );
        } else {
          customToast(
            "",
            true,
            "",
            "Enter correct OTP",
            "Entered OTP is wrong or it has been expired",
            "Try Again",
            `${ADMIN_ROUTE_PATHS.ADMIN_LAYOUT}${ADMIN_ROUTE_PATHS.INDEX}`,
          );
        }
      } else {
        customToast(
          "",
          true,
          "",
          "Fill all fields",
          "In order to continue, you must fill all the fields",
          "Go to Dashboard",
          `${ADMIN_ROUTE_PATHS.ADMIN_LAYOUT}${ADMIN_ROUTE_PATHS.INDEX}`,
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  const verifyChangePasswordOTP = async () => {
    try {
      // verifying the entered email
      if (newPassword !== confirmPassword) {
        setError("Passwords do not match.");
        setTimeout(() => {
          setError("");
        }, 3000);
        setNewPassword("");
        setConfirmPassword("");
      } else if (
        currPassword.length === 0 ||
        newPassword.length === 0 ||
        confirmPassword.length === 0
      ) {
        setError("Please fill all the fields");
        setTimeout(() => {
          setError("");
        }, 3000);
      } else {
        var sha = sha256(currPassword);
        const isCurrentPasswordValid = await hashPassword(email, sha);
        if (isCurrentPasswordValid.data.success === true) {
          const res = await sendOtpToNewEmail(email, userId);
          if (res.data.success == true) {
            setModalChangeOTP(true);
            setError("");
            customToast(
              true,
              "",
              "",
              "OTP sent",
              "Kindly check your registered mail address and enter the OTP",
              "Check Email",
              ""
            );
          } else {
            setModalChangeOTP(false);
            customToast(
              "",
              true,
              "",
              "Enter correct OTP",
              "Entered OTP is wrong or it has been expired",
              "Go to Dashboard",
              `${ADMIN_ROUTE_PATHS.ADMIN_LAYOUT}${ADMIN_ROUTE_PATHS.INDEX}`
            );
          }
        } else {
          customToast(
            "",
            true,
            "",
            "Error with current password",
            `${isCurrentPasswordValid.data.msg}`,
            "Go to Profile",
            `${ADMIN_ROUTE_PATHS.ADMIN_TEST_LAYOUT}${ADMIN_ROUTE_PATHS.EDIT_PROFILE}`
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const verifySetup2Fa = async () => {
    try {
      // verifying the entered email
      const res = await sendOtpToEnable(email, userId);
      if (res.data.success == true) {
        setTwoFaOtp(true);
        customToast(
          true,
          "",
          "",
          "OTP sent",
          "Kindly check your registered mail address and enter the OTP",
          "Check Email",
          ""
        );
      } else {
        customToast(
          "",
          true,
          "",
          "Enter correct OTP",
          "Entered OTP is wrong or it has been expired",
          "Go to Dashboard",
          `${ADMIN_ROUTE_PATHS.ADMIN_LAYOUT}${ADMIN_ROUTE_PATHS.INDEX}`
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const verifyDisable2Fa = async () => {
    try {
      // verifying the entered email
      const res = await sendOtpToDisable(email, userId);
      if (res.data.success == true) {
        setDisableTwoFaOtp(true);
        customToast(
          true,
          "",
          "",
          "OTP sent",
          "Kindly check your registered mail address and enter the OTP",
          "Check Email",
          ""
        );
      } else {
        customToast(
          "",
          true,
          "",
          "Enter correct OTP",
          "Entered OTP is wrong or it has been expired",
          "Go to Dashboard",
          `${ADMIN_ROUTE_PATHS.ADMIN_LAYOUT}${ADMIN_ROUTE_PATHS.INDEX}`
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  let base64Code = "";
  // onChange function for uploading
  const uploadImage = (e) => {
    const file = e.target.files[0];
    console.log(file, "ff");
    if (!error) {
      // console.log("type", file.type, file.size);
      if (
        file.type !== "image/jpeg" &&
        file.type !== "image/png" &&
        file.type !== "image/jpg"
      ) {
        setError(`${file.name} format is not supported.`);
        setImage("");
        e.target.value = null;
        return;
      } else if (file.size > 1024 * 1024 * 5) {
        setError(`${file.name} is too large max 5MB allowed.`);
        setImage("");
        e.target.value = null;
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        onLoad(reader.result);
        // setImage(reader.result);
      };
    }
  };

  const onLoad = (fileString) => {
    base64Code = fileString;
    setImage(base64Code);
    // console.log(base64Code, 'onLoad');
  };
  const [loadImage, setLoadImage] = useState(false);
  const upload = async () => {
    if (!image) {
      customToast(
        "",
        true,
        "",
        "Please select image",
        "In order to update profile picture, you need to select image.",
        "Go to Dashboard",
        `${ADMIN_ROUTE_PATHS.ADMIN_LAYOUT}${ADMIN_ROUTE_PATHS.INDEX}`
      );
    } else {
      try {
        setLoadImage(true);
        const res = await saveProfilePicture(AdminEmail, image);
        if (res.data.success === true) {
          customToast(
            true,
            "",
            "",
            "Profile Picture Updated",
            "New avatar has been updated.",
            "Go to Profile",
            `${ADMIN_ROUTE_PATHS.ADMIN_TEST_LAYOUT}${ADMIN_ROUTE_PATHS.EDIT_PROFILE}`
          );
          toggle();
          toggleUpdateDetails();
          setImage("");
          setLoadImage(false);
          console.log("GetUserInfo called upload");
          const { data } = await getUserInfo(
            JSON.parse(localStorage.getItem("user"))?.email
          );
          const value = data?.userinfo[0]?.profilePicture;
          if (!value || value === null || value === undefined) {
            setDecodedImage("https://www.arishti.in/images/group-image2.webp");
          } else {
            setDecodedImage(value);
          }
        } else {
          customToast(
            true,
            "",
            "",
            "Error occurred",
            `${res.data.message}`,
            "Go to Profile",
            `${ADMIN_ROUTE_PATHS.ADMIN_TEST_LAYOUT}${ADMIN_ROUTE_PATHS.EDIT_PROFILE}`
          );
          toggle();
          toggleUpdateDetails();
          setImage("");
        }
      } catch (error) {
        if (
          error.response &&
          (error.response.status === 429 || error.response.status === 403)
        ) {
          customToast(
            "",
            true,
            "",
            "Too many requests",
            `${error.response.data.message
              ? error.response.data.message
              : error.response.message
            }`,
            "Reload the website",
            ""
          );
        }
      }
    }
  };

  // const decodeFileBase64 = (profilePic) => {
  //   return decodeURIComponent(
  //     btoa(profilePic)
  //       .split("")
  //       .map(function (c) {
  //         return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
  //       })
  //       .join("")
  //   );
  // };

  // const decodeBase64 = decodeFileBase64(
  //   image.substring(image.indexOf(",") + 1)
  // );

  // console.log("decoded string converted back to img", decodeBase64);
  // console.log('image', image);
  // setImage(fileBase64String);

  useEffect(() => {
    const getImage = async () => {
      try {
        console.log("GetUserInfo called");
        const { data } = await getUserInfo(
          JSON.parse(localStorage.getItem("user"))?.email
        );
        console.log(data, "data getuserinfo");
        try {
          const value = data?.userinfo[0]?.profilePicture;
          if (!value || value === null || value === undefined) {
            setDecodedImage("https://www.arishti.in/images/group-image2.webp");
          } else {
            setDecodedImage(value);
          }
        } catch (error) {
          setDecodedImage("https://www.arishti.in/images/group-image2.webp");
        }
      } catch (error) {
        console.log(error, "edit profile error");
        if (
          error.response &&
          (error.response.status === 429 || error.response.status === 403)
        ) {
          console.log(error.response, "getuserinfo error");
          customToast(
            "",
            true,
            "",
            "Too many requests",
            `${error.response.data.message
              ? error.response.data.message
              : error.response.message
            }`,
            "Reload the website",
            ""
          );
        }
      }
    };
    getImage();
  }, []);

  // function shifter to subscription page
  const apply = async () => {
    const org_token = JSON.parse(localStorage.getItem("user"))?.orgToken;
    const { data } = await applyCoupoun(couponCode, org_token);
    if (data.success) {
      const newExp = await findOrganizationDetails(org_token);
      if (newExp.success === true) {
        localStorage.setItem(
          "expiryDate",
          JSON.stringify(newExp.msg.expiry_date)
        );
      }

      customToast(
        true,
        "",
        "",
        "Enterprise coupon activated!",
        "Your license has been activated, kindly re-login to take the effect.",
        "Go to Subscription",
        `${ADMIN_ROUTE_PATHS.ADMIN_TEST_LAYOUT}${ADMIN_ROUTE_PATHS.SUBSCRIPTION}`
      );
      window.location.reload();
    } else {
      customToast(
        "",
        true,
        "",
        "Some error occurred",
        "Failed to apply the coupon",
        "Go to Profile",
        `${ADMIN_ROUTE_PATHS.ADMIN_TEST_LAYOUT}${ADMIN_ROUTE_PATHS.EDIT_PROFILE}`
      );

      customToast(
        "",
        true,
        "",
        "Enterprise coupon failed",
        "Failed to apply the coupon",
        "Go to Subscription",
        `${ADMIN_ROUTE_PATHS.ADMIN_TEST_LAYOUT}${ADMIN_ROUTE_PATHS.SUBSCRIPTION}`
      );
    }
  };

  const handleDeleteAdmin = (id) => {
    setDeletingAdmin(id);
    toggleDeleteAdmin();
  };

  const deleteAdmin = async () => {
    toggleDeleteAdmin();
    try {
      const res = await deleteNormalAdmin(deletingAdmin, userId);
      // * IF I will file activeSession query here then the superAdmin will also be logged out
      console.log(res);
      if (res.success === true) {
        dispatch(getAllAdminsAction(orgTokenLS));

        customToast(
          true,
          "",
          "",
          "Admin deleted",
          "The deleted admin will no longer have access to Admin Panel",
          "Go to Profile",
          `${ADMIN_ROUTE_PATHS.ADMIN_TEST_LAYOUT}${ADMIN_ROUTE_PATHS.EDIT_PROFILE}`
        );
      } else {
        customToast(
          "",
          true,
          "",
          "Admin deletion failed",
          "Some error occurred",
          "Go to Profile",
          `${ADMIN_ROUTE_PATHS.ADMIN_TEST_LAYOUT}${ADMIN_ROUTE_PATHS.EDIT_PROFILE}`
        );
      }
    } catch (error) {
      customToast(
        "",
        true,
        "",
        "Something went wrong",
        "Logout and Login again",
        "",
        ""
      );
    }
  };

  // Function to change admins password
  const changePassword = async () => {
    try {
      var sha = sha256(currPassword);
      var sha1 = sha256(newPassword);
      // console.log(sha, "lqq");
      if (currPassword.length && newPassword.length && confirmPassword.length) {
        const res = await hashPassword(email, sha);
        if (res.data.success === true) {
          if (
            newPassword !== confirmPassword ||
            !newPassword.length ||
            !confirmPassword.length
          ) {
            setError("Passwords do not match.");
          } else {
            setLoadChangePassword(true);
            const { data } = await verifiedOtp(userId, OTPChangePassword);
            if (data.success === true) {
              const { data } = await changeCurrentPassword(userId, email, sha1);
              setModalChangeOTP(false);
              if (data.success === true) {
                customToast(
                  true,
                  "",
                  "",
                  "Password updated, Kindly re-login",
                  "You will be logged out of admin panel, kindly re-login to continue",
                  "Logout",
                  `${AUTH_ROUTE_PATHS.AUTH_LAYOUT}${AUTH_ROUTE_PATHS.LOGIN}`
                );

                setCurrPassword("");
                setNewPassword("");
                setConfirmPassword("");
                setPassword("");
                setOtpChangePassword("");
                setLoadChangePassword(false);
              } else {
                customToast(
                  "",
                  true,
                  "",
                  "Some error occurred",
                  "Try again",
                  "Go to Profile",
                  `${ADMIN_ROUTE_PATHS.ADMIN_TEST_LAYOUT}${ADMIN_ROUTE_PATHS.EDIT_PROFILE}`
                );
                setOtpChangePassword("");
                setLoadChangePassword(false);
              }
            } else {
              customToast(
                "",
                true,
                "",
                "Enter correct OTP",
                `${data.message}`,
                "Go to Dashboard",
                `${ADMIN_ROUTE_PATHS.ADMIN_LAYOUT}${ADMIN_ROUTE_PATHS.INDEX}`
              );
              setOtpChangePassword("");
              setLoadChangePassword(false);
            }
          }
        } else {
          customToast(
            "",
            true,
            "",
            `${res.data.msg}`,
            "Try again",
            "Go to Profile",
            `${ADMIN_ROUTE_PATHS.ADMIN_TEST_LAYOUT}${ADMIN_ROUTE_PATHS.EDIT_PROFILE}`
          );
          setLoadChangePassword(false);
        }
      } else {
        customToast(
          "",
          true,
          "",
          "Please fill all the fields",
          "Fill all the blanks",
          "Go to Profile",
          `${ADMIN_ROUTE_PATHS.ADMIN_TEST_LAYOUT}${ADMIN_ROUTE_PATHS.EDIT_PROFILE}`
        );
        setLoadChangePassword(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 429) {
        customToast(
          "",
          true,
          "",
          "Too many wrong attempts",
          "Number of attempts exceeded",
          "Try Again after 10 minutes",
          ""
        );
      }
    }
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setNewPassword(newPassword);

    // Password validation rules
    const hasValidLength = newPassword.length >= 8;
    const hasAlphabet = /[a-zA-Z]/.test(newPassword);
    const hasNumber = /[0-9]/.test(newPassword);
    const hasSpecialChar = /[!@#%^&*()_+{}\[\]:;<>,.?~\-\/\\]/.test(
      newPassword
    );
    const hasNoDollar = !/\$/.test(newPassword); // Excluding $
    setIsPasswordValid(
      hasValidLength &&
      hasAlphabet &&
      hasNumber &&
      hasSpecialChar &&
      hasNoDollar
    );
    if (isPasswordValid) {
      setPasswordErrorMessage("");
    } else {
      setPasswordErrorMessage(
        "Password must be 8+ chars with num & special symbol except $."
      );
    }
  };

  const handlePasswordBlur = () => {
    // Password validation rules
    const hasValidLength = newPassword.length >= 8;
    const hasAlphabet = /[a-zA-Z]/.test(newPassword);
    const hasNumber = /[0-9]/.test(newPassword);
    const hasSpecialChar = /[!@#%^&*()_+{}\[\]:;<>,.?~\-\/\\]/.test(
      newPassword
    );
    const hasNoDollar = !/\$/.test(newPassword); // Excluding $
    setIsPasswordValid(
      hasValidLength &&
      hasAlphabet &&
      hasNumber &&
      hasSpecialChar &&
      hasNoDollar
    );
    if (isPasswordValid) {
      setPasswordErrorMessage("");
    } else {
      setPasswordErrorMessage(
        "Password must be 8+ chars with num & special symbol except $."
      );
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);

    // Compare with the original password
    setIsPasswordMatch(newConfirmPassword === newPassword);
    if (isPasswordMatch) {
      setConfirmPasswordErrorMessage("");
    } else {
      setConfirmPasswordErrorMessage("Passwords do not match");
    }
  };

  const handleConfirmPasswordBlur = () => {
    setIsPasswordMatch(confirmPassword === newPassword);
    if (isPasswordMatch) {
      setConfirmPasswordErrorMessage("");
    } else {
      setConfirmPasswordErrorMessage("Passwords do not match");
    }
  };
  const clearPasswordErrors = () => {
    setIsPasswordValid(true);
  };

  const clearPasswordMatchError = () => {
    setIsPasswordMatch(true);
  };

  const handleNewAdmin = (item) => {
    // email should be sent
    console.log(item, "item");
    setAdminModal(!adminModal);
    setManageEmail(item.email);
    setAdminPermissions({
      delete: item.adminPermissions.deleteUser,
      FSL: item.adminPermissions.fsl,
      groupSettings: item.adminPermissions.groupSettings,
      wipe: item.adminPermissions.remoteWipe,
      fileSharingPrevention: item.adminPermissions.fileSharingPrevention,
    });
    setExistingAdminPermissions({
      delete: item.adminPermissions.deleteUser,
      FSL: item.adminPermissions.fsl,
      groupSettings: item.adminPermissions.groupSettings,
      wipe: item.adminPermissions.remoteWipe,
      fileSharingPrevention: item.adminPermissions.fileSharingPrevention,
    });
    newAdmin();
  };

  const grantAdminPermissions = async () => {
    try {
      const { data } = await updateAdminPermissions(
        manageEmail,
        adminPermissions.delete,
        adminPermissions.FSL,
        adminPermissions.groupSettings,
        adminPermissions.wipe,
        adminPermissions.fileSharingPrevention,
        userId
      );
      if (data.success) {
        customToast(
          true,
          "",
          "",
          "Permissions Updated!",
          `${data.message}`,
          "Go to Profile",
          `${ADMIN_ROUTE_PATHS.ADMIN_TEST_LAYOUT}${ADMIN_ROUTE_PATHS.EDIT_PROFILE}`,
          ""
        );
        window.location.reload();
      } else {
        customToast(
          "",
          true,
          "",
          "Some error occurred",
          `${data.message}`,
          "",
          ""
        );
      }
      newAdmin();
    } catch (error) {
      customToast(
        "",
        true,
        "",
        "Some error occurred",
        `Try again later`,
        "",
        ""
      );
      newAdmin();
    }
  };

  // REINVITE USER
  const reinviteUser = async (user) => {
    console.log(user, "user");
    const data = await reinviteAdminUser(user.email, user._id);
    if (data.success) {
      customToast(
        true,
        "",
        "",
        "Reinvited Admin Successfully!",
        `Admin can onboard now`,
        "Go to Profile",
        `${ADMIN_ROUTE_PATHS.ADMIN_TEST_LAYOUT}${ADMIN_ROUTE_PATHS.EDIT_PROFILE}`,
        ""
      );
    }
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* AVATAR UPDATE MODAL */}
      <Modal
        isOpen={modal}
        toggle={toggle}
        className=""
        style={{
          width: "25%",
          marginLeft: "38%",
          marginTop: "5%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ModalHeader className="text-dark" toggle={toggle}>
          Update Your New Avatar
        </ModalHeader>
        {error ? (
          <p style={{ marginLeft: "20px", color: "red" }}>{error}</p>
        ) : (
          ""
        )}
        <ModalBody className="text-dark">
          <input
            type="file"
            id="file"
            name="file"
            onChange={uploadImage}
            accept="image/jpeg,image/png, image/jpg"
          ></input>
        </ModalBody>
        <ModalFooter>
          <Button
            // className={`${!image ? "cursor-not-allowed" : "bg-meme"}`}
            style={{
              borderRadius: "54px",
              backgroundColor: "#3107CB",
              borderColor: "#3107CB",
              boxShadow: "none",
              cursor: !image || loadImage ? "not-allowed" : "",
            }}
            onClick={upload}
            disabled={!image || loadImage}
          >
            Upload
          </Button>
          <Button
            // color="secondary"
            style={{
              borderRadius: "54px",
              backgroundColor: "#D9D9D9",
              borderColor: "#D9D9D9",
              boxShadow: "none",
            }}
            onClick={() => {
              toggle();
              setError("");
              setImage("");
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      {/* MODAL FOR UPDATING ADMIN PERMISSIONS */}
      <Modal
        isOpen={adminModal}
        toggle={newAdmin}
        centered
        size="md"
        style={{
          height: "200px",
        }}
      >
        <Cards
          style={{
            height: "150px",
          }}
        >
          <Image
            style={{
              marginTop: "5px",
              width: "70%",
              height: "50% !important",
            }}
            className="ml-5"
            src={
              dashboardImage
            }
            alt="Card Image"
          />
          {/* <CardBody> */}
          <H6 color="gray">Admin Roles & Permission</H6>

          <div style={{ color: "#141414", fontSize: "12px" }}>
            <p className="mb-2">
              You can update the existing permissions of your admin here.
            </p>
            <p className="mb-2">
              Your changes will reflect instantly when the admin logs-in the
              next time after taking effect.
            </p>
            <p>
              Existing permissions for <b>{manageEmail}</b>:
            </p>
            <div className=" ml-2">
              {!existingAdminPermissions.delete &&
                !existingAdminPermissions.FSL &&
                !existingAdminPermissions.groupSettings &&
                !existingAdminPermissions.wipe &&
                !existingAdminPermissions.fileSharingPrevention ? (
                <p style={{ fontSize: "13px", color: "#C23838" }}>
                  No permissions are granted yet.
                </p>
              ) : (
                <div className="d-flex flex-col">
                  <div className="d-flex ml-3 py-2 w-100">
                    {existingAdminPermissions.delete ? (
                      <div
                        className="d-flex align-items-center"
                        style={{ paddingRight: "5.5rem" }}
                      >
                        <IoCheckmarkCircle
                          size={20}
                          color=" #47BE9B"
                          className="mr-2"
                        />
                        <h1>Delete</h1>
                      </div>
                    ) : (
                      <div
                        className="d-flex align-items-center"
                        style={{ paddingRight: "5.5rem" }}
                      >
                        <MdCancel size={20} color=" #C23838" className="mr-2" />
                        <h1>Delete</h1>
                      </div>
                    )}
                    {existingAdminPermissions.wipe ? (
                      <div className="d-flex align-items-center">
                        <IoCheckmarkCircle
                          size={20}
                          color=" #47BE9B"
                          className="mr-2"
                        />
                        <h1>Remote Wipe</h1>
                      </div>
                    ) : (
                      <div className="d-flex align-items-center">
                        <MdCancel size={20} color=" #C23838" className="mr-2" />
                        <h1>Remote Wipe</h1>
                      </div>
                    )}
                  </div>
                  <div className="d-flex ml-3 py-2 w-100">
                    {existingAdminPermissions.FSL ? (
                      <div
                        className="d-flex align-items-center"
                        style={{ paddingRight: "2.3rem" }}
                      >
                        <IoCheckmarkCircle
                          size={20}
                          color=" #47BE9B"
                          className="mr-2"
                        />
                        <h1>File Size Limiter</h1>
                      </div>
                    ) : (
                      <div
                        className="d-flex align-items-center"
                        style={{ paddingRight: "1.6rem" }}
                      >
                        <MdCancel size={20} color=" #C23838" className="mr-2" />
                        <h1 className="mr-2">File Size Limiter</h1>
                      </div>
                    )}
                    {existingAdminPermissions.groupSettings ? (
                      <div className="d-flex align-items-center">
                        <IoCheckmarkCircle
                          size={20}
                          color=" #47BE9B"
                          className="mr-2"
                        />
                        <h1>Group Settings</h1>
                      </div>
                    ) : (
                      <div className="d-flex  align-items-center">
                        <MdCancel size={20} color=" #C23838" className="mr-2" />
                        <h1>Group Settings</h1>
                      </div>
                    )}
                  </div>
                  <div className="d-flex ml-3 py-2 w-100">
                    {existingAdminPermissions.fileSharingPrevention ? (
                      <div className="d-flex align-items-center">
                        <IoCheckmarkCircle
                          size={20}
                          color=" #47BE9B"
                          className="mr-2"
                        />
                        <h1>File Sharing Restriction</h1>
                      </div>
                    ) : (
                      <div className="d-flex  align-items-center">
                        <MdCancel size={20} color=" #C23838" className="mr-2" />
                        <h1>File Sharing Restriction</h1>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* </CardBody> */}
          <div
            className="d-flex text-black pt-2 flex-col"
            style={{
              fontSize: "13px",
            }}
          >
            <p style={{ color: "#141414" }}>Update the permissions as below:</p>

            <div className="d-flex align-items-center ml-4">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={adminPermissions.delete}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                    name="delete"
                    size="small"
                    color="primary"
                  />
                }
                label="Delete"
                style={{
                  color: "#141414",
                  fontWeight: "300",
                  paddingRight: "4.2rem",
                }}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={adminPermissions.wipe}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                    name="wipe"
                    size="small"
                    color="primary"
                  />
                }
                label="Remote Wipe"
                style={{ color: "#141414" }}
              />
            </div>
            <div className="d-flex align-items-center ml-4">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={adminPermissions.FSL}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                    name="FSL"
                    size="small"
                    color="primary"
                  />
                }
                label="File Size Limiter"
                style={{ color: "#141414" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={adminPermissions.groupSettings}
                    onChange={handleChange}
                    size="small"
                    name="groupSettings"
                    color="primary"
                  />
                }
                label="Group Settings"
                style={{ color: "#141414" }}
              />
            </div>
            <div className="d-flex align-items-center ml-4">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={adminPermissions.fileSharingPrevention}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                    name="fileSharingPrevention"
                    size="small"
                    color="primary"
                  />
                }
                label="File Sharing Restriction"
                style={{ color: "#141414" }}
              />
            </div>

            <div className="d-flex align-items-center mt--3 ml-4">
              <div className="w-40 align-items-center mt-4 text-black">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={terms}
                      onChange={({ target: { checked } }) => {
                        setTerms(checked);
                      }}
                      size="small"
                      color="primary"
                    />
                  }
                  label="Accept T&C"
                  style={{ color: "#141414" }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  // marginLeft: "20px",
                  // paddingBottom: "10px",
                }}
                className="btns d-flex align-items-center w-50 mt-4"
              >
                {manageEmail.length > 0 && (
                  <Button
                    disabled={!terms}
                    variant="primary"
                    style={{ width: "100%", borderRadius: "50px" }}
                    onClick={() => grantAdminPermissions()}
                  >
                    Apply
                  </Button>
                )}
                <Button
                  onClick={() => {
                    newAdmin();
                    setAdminPermissions(false);
                    setTerms(false);
                  }}
                  variant="light"
                  style={{ width: "100%", borderRadius: "50px" }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </Cards>
      </Modal>

      {/* MODAL FOR VERIFYING OTP */}
      <Modal
        isOpen={modalOTP}
        toggle={toggleOTP}
        className=""
        style={{
          width: "25%",
          marginLeft: "38%",
          marginTop: "5%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Cards>
          <div className="d-flex flex-col">
            <h5 className="text-black mb-1">
              Enter the OTP sent on your mail.
            </h5>

            <FormGroup className="form-group" style={{ marginTop: "10px" }}>
              <div className="floating-label">
                <input
                  id="outlined-size-normal"
                  className="form-control"
                  type="number"
                  style={{
                    backgroundColor: "#EDF2F7",
                    border: "none",
                  }}
                  name="otp"
                  required
                  onChange={(e) => setOTP(e.target.value)}
                  value={OTP}
                />
                <label
                  for="otp"
                  style={{
                    color: "#455768",
                    backgroundColor: "#EDF2F7",
                  }}
                >
                  OTP
                </label>
              </div>
            </FormGroup>
            <div className="d-flex">
              <Button
                style={{
                  borderRadius: "54px",
                  backgroundColor: "#3107CB",
                  borderColor: "#3107CB",
                  boxShadow: "none",
                  cursor: loadUpdateProfile ? "not-allowed" : "",
                }}
                onClick={editUser}
                disabled={loadUpdateProfile}
              >
                Submit
              </Button>
              <Button
                style={{
                  borderRadius: "54px",
                  backgroundColor: "#D9D9D9",
                  borderColor: "#D9D9D9",
                  boxShadow: "none",
                }}
                onClick={() => toggleOTP()}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Cards>
      </Modal>

      {/* MODAL FOR CHANGING PASSWORD */}
      <Modal isOpen={modalChangeOTP} toggle={toggleChangeOTP} className="">
        <Cards>
          <div className="d-flex flex-col">
            <h5 className="text-black mb-1">
              Enter the OTP sent on your mail.
            </h5>

            <FormGroup className="form-group" style={{ marginTop: "10px" }}>
              <div className="floating-label">
                <input
                  id="outlined-size-normal"
                  className="form-control"
                  type="number"
                  style={{
                    backgroundColor: "#EDF2F7",
                    border: "none",
                  }}
                  name="otp"
                  required
                  onChange={(e) => setOtpChangePassword(e.target.value)}
                  value={OTPChangePassword}
                />
                <label
                  for="otp"
                  style={{
                    color: "#455768",
                    backgroundColor: "#EDF2F7",
                  }}
                >
                  OTP
                </label>
              </div>
            </FormGroup>
            <div className="d-flex">
              <Button
                style={{
                  borderRadius: "54px",
                  backgroundColor: "#3107CB",
                  borderColor: "#3107CB",
                  boxShadow: "none",
                  cursor: loadChangePassword ? "not-allowed" : "",
                }}
                onClick={changePassword}
                disabled={loadChangePassword}
              >
                Submit
              </Button>
              <Button
                style={{
                  borderRadius: "54px",
                  backgroundColor: "#D9D9D9",
                  borderColor: "#D9D9D9",
                  boxShadow: "none",
                }}
                onClick={() => toggleChangeOTP()}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Cards>
      </Modal>
      {/* MODAL FOR ENABLING 2FA */}
      <Modal isOpen={twoFaOtp} toggle={toggleTwoFA} className="">
        <Cards>
          <div className="d-flex flex-col">
            <h5 className="text-black mb-1">
              Enter the OTP sent to your mail to enable two factor
              authentication.
            </h5>

            <FormGroup className="form-group" style={{ marginTop: "10px" }}>
              <div className="floating-label">
                <input
                  id="outlined-size-normal"
                  className="form-control"
                  type="number"
                  style={{
                    backgroundColor: "#EDF2F7",
                    border: "none",
                  }}
                  name="otp"
                  required
                  onChange={(e) => setOTP2Fa(e.target.value)}
                  value={OTP2Fa}
                />
                <label
                  for="otp"
                  style={{
                    color: "#455768",
                    backgroundColor: "#EDF2F7",
                  }}
                >
                  OTP
                </label>
              </div>
            </FormGroup>
            <div className="d-flex">
              <Button
                style={{
                  borderRadius: "54px",
                  backgroundColor: "#3107CB",
                  borderColor: "#3107CB",
                  boxShadow: "none",
                  cursor: load2Fa ? "not-allowed" : "",
                }}
                onClick={setup2Fa}
                disabled={load2Fa}
              >
                Submit
              </Button>
              <Button
                style={{
                  borderRadius: "54px",
                  backgroundColor: "#D9D9D9",
                  borderColor: "#D9D9D9",
                  boxShadow: "none",
                }}
                onClick={toggleTwoFA}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Cards>
      </Modal>
      {/* MODAL FOR DISABLING 2FA */}
      <Modal isOpen={disableTwoFaOtp} toggle={toggleDisableTwoFA} className="">
        <Cards>
          <div className="d-flex flex-col">
            <h5 className="text-black mb-1">
              Enter the OTP sent to your mail to disable two factor
              authentication.
            </h5>

            <FormGroup className="form-group" style={{ marginTop: "10px" }}>
              <div className="floating-label">
                <input
                  id="outlined-size-normal"
                  className="form-control"
                  type="number"
                  style={{
                    backgroundColor: "#EDF2F7",
                    border: "none",
                  }}
                  name="otp"
                  required
                  onChange={(e) => setOTP2Fa(e.target.value)}
                  value={OTP2Fa}
                />
                <label
                  for="otp"
                  style={{
                    color: "#455768",
                    backgroundColor: "#EDF2F7",
                  }}
                >
                  OTP
                </label>
              </div>
            </FormGroup>
            <div className="d-flex">
              <Button
                style={{
                  borderRadius: "54px",
                  backgroundColor: "#3107CB",
                  borderColor: "#3107CB",
                  boxShadow: "none",
                  cursor: load2Fa ? "not-allowed" : "",
                }}
                onClick={disable2Fa}
                disabled={load2Fa}
              >
                Submit
              </Button>
              <Button
                style={{
                  borderRadius: "54px",
                  backgroundColor: "#D9D9D9",
                  borderColor: "#D9D9D9",
                  boxShadow: "none",
                }}
                onClick={toggleDisableTwoFA}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Cards>
      </Modal>
      {/* MODAL FOR DELETING ADMIN */}
      <Modal
        isOpen={deleteAdminModal}
        toggle={toggleDeleteAdmin}
        centered
        size="md"
        style={{
          height: "200px",
        }}
      >
        <Cards
          style={{
            height: "150px",
          }}
        >
          <Image
            style={{
              marginTop: "5px",
              width: "100%",
              height: "50% !important",
            }}
            src={
              dashboardImage
            }
            alt="Card Image"
          />

          {/* <CardBody> */}
          <H6 color="gray">MessageMe™ Delete Admin</H6>
          <Paragraph>
            This feature should only be used under the supervision of Enterprise
            Executives. The employee will no longer be having access to his/her
            application data hereafter.
          </Paragraph>

          {/* </CardBody> */}

          <div
            style={{
              marginLeft: "20px",
              top: "-20px",
            }}
          >
            <div
              style={{
                display: "flex",
                marginBottom: "10px",
              }}
            >
              <span
                className="checkmark"
                style={{ width: "30px", height: "22px" }}
              >
                <div className="checkmark_stem"></div>
                <div
                  className="checkmark_kick"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                ></div>
              </span>
              <p
                style={{
                  marginLeft: "5px",
                  color: "black",
                  lineHeight: "22px",
                }}
              >
                Caution! This activity cannot be reverted. Kindly confirm before
                deleting the user.
              </p>
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "10px",
              }}
            >
              <span
                className="checkmark"
                style={{ width: "22px", height: "22px" }}
              >
                <div className="checkmark_stem"></div>
                <div
                  className="checkmark_kick"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                ></div>
              </span>
              <p
                style={{
                  marginLeft: "5px",
                  color: "black",
                  lineHeight: "22px",
                }}
              >
                Caution! The details of the user will be remotely wiped.
              </p>
            </div>
          </div>
          <div
            style={{
              marginLeft: "25px",
            }}
          >
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label="Accept Terms and Conditions"
                checked={deleteAdminUser}
                onChange={({ target: { checked } }) => {
                  setDeleteAdminUser(checked);
                }}
              />
            </Form.Group>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginLeft: "20px",
              paddingBottom: "10px",
            }}
            className="btns"
          >
            <Button
              disabled={!deleteAdminUser}
              onClick={deleteAdmin}
              variant="primary"
              style={{ borderRadius: "50px", width: "100%" }}
            >
              Yes
            </Button>
            <Button
              onClick={() => {
                toggleDeleteAdmin();
                setDeleteAdminUser(false);
              }}
              variant="light"
              style={{ borderRadius: "50px", width: "100%" }}
            >
              No
            </Button>
          </div>
        </Cards>
      </Modal>
      {/* MODAL FOR EDITING FIELDS */}
      <Modal isOpen={updateDetails} toggle={toggleUpdateDetails} className="">
        <div>
          <img
            src={subs}
            style={{
              objectFit: "none",
              width: "100%",
              // padding: "2px",
              borderTopLeftRadius: "1.3rem",
              borderTopRightRadius: "1.3rem",
            }}
          />
          <img
            alt="..."
            src={
              decodedImage
                ? decodedImage
                : "https://www.arishti.in/images/group-image2.webp"
            }
            style={{
              height: "70px",
              width: "70px",
              borderRadius: "100%",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25",
              position: "absolute",
              left: "31px",
              top: "58px",
            }}
            className="d-none d-md-block"
          />

          <FaEdit
            style={{
              position: "absolute",
              left: "80px",
              top: "110px",
              width: "15px",
              height: "15px",
              color: "black",
              cursor: "pointer",
            }}
            onClick={toggle}
          />

          <div className="d-flex flex-col pl-8">
            <h1
              style={{ color: "#3107cb", fontSize: "25px" }}
              className="font-bold"
            >
              {name}
            </h1>
            <p style={{ color: "#74798C" }}>
              {isSuperAdmin === true ? "Super Admin" : "Admin"}
            </p>
          </div>
        </div>

        <div style={{ color: "#585858" }} className="mt-4">
          <Form>
            <div className="pl-4 pr-4 pb-2">
              <Row>
                <Col>
                  <h6>Edit your profile details</h6>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Name
                    </label>
                    <Input
                      className="form-control-alternative"
                      value={name}
                      id="input-username"
                      placeholder="Username"
                      onChange={handleChangeName}
                      type="name"
                    />
                  </FormGroup>
                  {/* <FormGroup>
                    <label className="form-control-label" htmlFor="input-email">
                      Email address
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-email"
                      placeholder="Email Address"
                      value={email}
                      onChange={handleChangeEmail}
                      type="email"
                    />
                  </FormGroup> */}

                  {/* <FormGroup>
                    <label className="form-control-label" htmlFor="input-phone">
                      Phone
                    </label>
                    <Input
                      className="form-control-alternative"
                      value={phone}
                      id="input-phone"
                      placeholder="Phone"
                      onChange={handleChangePhone}
                      type="number"
                    />
                  </FormGroup> */}

                  <Button
                    style={{
                      borderRadius: "54px",
                      backgroundColor: "#3107CB",
                      borderColor: "#3107CB",
                      boxShadow: "none",
                    }}
                    onClick={verify}
                  >
                    Save
                  </Button>
                  <Button
                    style={{
                      borderRadius: "54px",
                      backgroundColor: "#D9D9D9",
                      borderColor: "#D9D9D9",
                      boxShadow: "none",
                    }}
                    onClick={toggleUpdateDetails}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      </Modal>
      <EditHeader />
      {/* Page content */}
      {/* My Account section - old ui*/}
      {/*
      <Container className="mt--8 mx-0 p-4">
        <Row>
          <div className="col-12 col-md-8 col-lg-8 p-0 pr-md-1">
            <Card>
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0 text-dark">
                      <b>My account</b>
                    </h3>
                    {isTestUser ? (
                      <h5>
                        You are not allowed to edit the test user. Create
                        another user to test this functionality
                      </h5>
                    ) : null}
                  </Col>
                  <Col className="text-right" xs="4">
                    {canEdit ? (
                      <Button size="sm" onClick={verify} className="bg-meme">
                        Verify
                      </Button>
                    ) : (
                      <Button
                        size="sm"
                        // color='primary'
                        onClick={() => {
                          setCanEdit(!canEdit);
                        }}
                        className="bg-meme"
                      >
                        Edit Profile
                      </Button>
                    )}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody style={{ backgroundColor: "white" }}>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    USER INFORMATION
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={name}
                            id="input-username"
                            placeholder="Username"
                            onChange={handleChangeName}
                            type="name"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email address
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            placeholder="Email Address"
                            value={email}
                            onChange={handleChangeEmail}
                            type="email"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-phone"
                          >
                            Phone
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={phone}
                            id="input-phone"
                            placeholder="Phone"
                            onChange={handleChangePhone}
                            type="number"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-orgtoken"
                          >
                            Organization Token
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={orgtoken}
                            id="input-orgtoken"
                            placeholder="Organisation Token"
                            onChange={(e) => setOrgToken(e.target.value)}
                            type="text"
                            readOnly={true}
                          />
                        </FormGroup>
                      </Col>

                      {/* <Col xs='4'>
												<Button type='submit' variant='primary'>
													Update
												</Button>
											</Col> 
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
    

          {/* SIDE CONTENT OF EDIT PROFILE */}
      {/* 
          <div className="col-12 col-md-4 col-lg-4 mt-sm-7 mt-md-0 p-0 pl-md-1">
            <Card>
              <CardHeader className="bg-white border-0">
                <div className="row justify-content-center">
                  <div
                    className="p-0"
                    style={({ borderRadius: "50%" }, { marginTop: "-35px" })}
                  >
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        src={
                          decodedImage
                            ? decodedImage
                            : "https://www.arishti.in/images/group-image2.webp"
                        }
                        style={({ height: "120px" }, { width: "120px" })}
                      />
                    </span>
                  </div>
                </div>
                <div className="row justify-content-end mt--5">
                  <Button
                    className=" p-0 btn-outline-light"
                    onClick={toggle}
                    style={
                      ({ backgroundColor: "transparent" }, { border: "0px" })
                    }
                  >
                    <i className="fas fa-edit fa-lg"></i>
                  </Button>
                </div>
                <div className="row justify-content-center pt-4 pb-1">
                  <h5
                    className="text-dark font-weight-bold "
                    style={{ textAlign: "center" }}
                  >
                    {name}
                  </h5>
                </div>
                <div className="row justify-content-center pt-1">
                  <h6
                    className="text-dark font-weight-bold"
                    style={{ textAlign: "center" }}
                  >
                    Admin
                  </h6>
                </div>
                <div className="row justify-content-center pt-0">
                  <h6
                    className="text-dark font-weight-bold"
                    style={{ textAlign: "center" }}
                  >
                    {orgtoken}
                  </h6>
                </div>
              </CardHeader>
            </Card>
          </div>
        </Row>
      </Container>
      
            */}
      {/* REPLICA - new ui*/}
      <Container className="mt--7 mx-0 p-4">
        <Row>
          <div className="col-12 col-md-8 col-lg-8 p-0 pr-md-1">
            <div
              style={{
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.06)",
                borderRadius: "0px 0px 25px 25px",
              }}
            >
              <img
                src={subs}
                style={{
                  objectFit: "contain",
                  width: "100%",
                }}
              />
              <img
                alt="..."
                src={
                  decodedImage
                    ? decodedImage
                    : "https://www.arishti.in/images/group-image2.webp"
                }
                style={{
                  height: "120px",
                  width: "120px",
                  borderRadius: "100%",
                  // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25",
                  position: "absolute",
                  left: "31px",
                  top: "58px",
                }}
                className="d-none d-md-block"
              />
              <CardBody
                style={{ backgroundColor: "white", paddingLeft: "120px" }}
              >
                <div className="pl-lg-5 pb-4" style={{ marginTop: "-20px" }}>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex flex-col ">
                      <h1
                        style={{ color: "#3107cb", fontSize: "25px" }}
                        className="font-bold"
                      >
                        {name}
                      </h1>
                      <p style={{ color: "#74798C" }}>
                        {isSuperAdmin === true ? "Super Admin" : "Admin"}
                      </p>
                    </div>

                    <div>
                      {
                        <Button
                          size="sm"
                          // color='primary'
                          onClick={() => {
                            // setCanEdit(!canEdit);
                            toggleUpdateDetails();
                            // verify();
                            // setCanEdit(!canEdit);
                          }}
                          className="bg-meme"
                          style={{ textAlign: "center" }}
                        >
                          <i className="fas fa-edit fa-lg mr-1"></i>
                          Edit Profile
                        </Button>
                      }
                    </div>
                  </div>
                </div>

                <div className="pl-lg-5">
                  <div
                    className="d-flex  justify-content-between"
                    style={{ color: "#74798C" }}
                  >
                    <div>
                      <h6>Email Address</h6>
                      <p>{email}</p>
                    </div>
                    <div>
                      <h6>Organization Name</h6>
                      <p>{phone}</p>
                    </div>
                    <div>
                      <h6>Organization Token</h6>
                      <p>{orgTokenLS}</p>
                    </div>
                  </div>
                </div>
              </CardBody>
            </div>
          </div>

          {/* SIDE CONTENT OF EDIT PROFILE */}
          <div className="col-12 col-md-4 col-lg-4 mt-sm-7 mt-md-0 p-0 pl-md-1">
            <div
              style={{
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: "14px",
                backgroundColor: "#3107cb",
              }}
            >
              <div className="d-flex justify-content-end  ">
                <AiTwotoneCloud
                  color="#47BE9B"
                  style={{
                    borderColor: "#ffffff",
                    width: "70px",
                    height: "70px",
                    position: "absolute",
                    top: "-12%",
                  }}
                />
              </div>

              <CardHeader className=" border-0">
                {/* <div className="row justify-content-center">
                  <div
                    className="p-0"
                    style={({ borderRadius: "50%" }, { marginTop: "-35px" })}
                  >
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        src={
                          decodedImage
                            ? decodedImage
                            : "https://www.arishti.in/images/group-image2.webp"
                        }
                        style={({ height: "120px" }, { width: "120px" })}
                      />
                    </span>
                  </div>
                </div> */}
                <div className="d-flex flex-col justify-content-start align-items-start">
                  <h6
                    className="text-white font-weight-bold "
                    style={{ textAlign: "center" }}
                  >
                    Your current plan:
                  </h6>
                  <p className="text-uppercase text-white mb-2">
                    {whichPlanSubscribed}
                  </p>

                  <li className="text-white font-bold">
                    {calcDays?.dayDiff} days left
                  </li>
                  <li className="text-white font-bold">
                    Expiry Date: {findExpiryDate(expiryDate)}
                  </li>
                  <li className="text-white font-bold mb-4">
                    {/* {whichPlanSubscribed === "premium" ||
                    whichPlanSubscribed === "basic"
                      ? "You can onboard users upto 200."
                      : "You can onboard 7 users only"} */}
                    You can onboard users upto{" "}
                    {orgDetail?.onBoardUserLimit - calcDays?.users}
                  </li>

                  <p className="text-white " style={{ fontSize: "14px" }}>
                    Upgrade to Premium plan to invite more users and secure your
                    communications now
                  </p>
                </div>
                <div className="d-flex justify-content-end align-items-center">
                  <Button
                    className="text-white"
                    style={{
                      backgroundColor: "rgba(255, 255, 255, 0.58)",
                      borderRadius: "28px",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                      borderColor: "rgba(255, 255, 255, 0.58)",
                    }}
                    onClick={() =>
                      (window.location.href = `${ADMIN_ROUTE_PATHS.ADMIN_LAYOUT}${ADMIN_ROUTE_PATHS.SUBSCRIPTION}`)
                    }
                  >
                    Upgrade Plan
                  </Button>
                </div>
                <div className="d-flex align-items-start">
                  <div className="absolute d-none d-lg-block message_circle">
                    <AiOutlineMessage
                      color="#ffffff"
                      style={{
                        width: "30px",
                        height: "30px",
                        marginLeft: "10px",
                        marginTop: "10px",
                      }}
                    />
                  </div>
                </div>
                {/* <div className="row justify-content-end mt--5">
                  <Button
                    className=" p-0 btn-outline-light"
                    onClick={toggle}
                    style={
                      ({ backgroundColor: "transparent" }, { border: "0px" })
                    }
                  >
                    <i className="fas fa-edit fa-lg"></i>
                  </Button>
                </div>
                <div className="row justify-content-center pt-4 pb-1">
                  <h5
                    className="text-dark font-weight-bold "
                    style={{ textAlign: "center" }}
                  >
                    {name}
                  </h5>
                </div>
                <div className="row justify-content-center pt-1">
                  <h6
                    className="text-dark font-weight-bold"
                    style={{ textAlign: "center" }}
                  >
                    Admin
                  </h6>
                </div>
                <div className="row justify-content-center pt-0">
                  <h6
                    className="text-dark font-weight-bold"
                    style={{ textAlign: "center" }}
                  >
                    {orgtoken}
                  </h6>
                </div> */}
              </CardHeader>
            </div>
          </div>
        </Row>
      </Container>
      <Container className="mt--2 mx-0 p-4">
        <Row>
          <div className="col-12 col-md-8 col-lg-5 p-0 pr-md-1">
            <div
              style={{
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.06)",
                borderRadius: "4px",
                border: "1px solid rgba(0,0,0,0.125)",
              }}
            >
              <div
                className="d-flex align-items-start"
                style={{
                  width: "15px",
                  height: "103px",
                  background: " #47BE9B",
                  borderRadius: "4px 0px 0px 4px",
                  position: "absolute",
                }}
              ></div>
              <CardBody
                style={{ backgroundColor: "white", paddingLeft: "30px" }}
              >
                <h6 className=" text-muted font-bold">
                  Two Factor Authentication
                </h6>
                <div className="">
                  <div
                    style={{
                      color: "#74798C",
                    }}
                    className="d-flex align-items-center justify-content-between"
                  >
                    <p style={{ fontSize: "13px" }}>
                      Kindly setup 2FA for better security of your account
                    </p>
                    <ToggleSwitch
                      enable={verifySetup2Fa}
                      disable={verifyDisable2Fa}
                      isToggled={isToggled}
                      onToggle={(isToggled) => setIsToggled(!isToggled)}
                    />
                  </div>
                </div>
              </CardBody>
            </div>
          </div>
        </Row>
      </Container>
      {/* ACCOUNT SUBSCRIPTION */}
      {/* <Container className="mt--2 mx-0 p-4">
        <Row>
          <div className="col-12 col-md-8 col-lg-8 p-0 pr-md-1">
            <div
              style={{
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.06)",
                borderRadius: "4px",
              }}
            >
              <CardBody style={{ backgroundColor: "white" }}>
                <h3
                  style={{
                    color: "#74798C",
                    marginBottom: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                >
                  <b>Account Subscription</b>
                </h3>
                <Collapse isOpen={isOpen}>
                  <div
                    style={{
                      display: "flex",
                      gap: "150px",
                      marginBottom: "30px",
                    }}
                  >
                    <p style={{ color: "black" }}>
                      <b>Expiry Date: </b>
                    </p>
                    <p style={{ color: "black" }}>
                      {" "}
                      {expiryDate.expiry_date
                        .split("T")[0]
                        .split("-")
                        .reverse()
                        .join("/")}{" "}
                    </p>
                  </div>
                  <p style={{ color: "black", marginBottom: "5px" }}>
                    <b>Coupon Code:</b>
                  </p>

                  <Form>
                    <Row className="row-cols-lg-auto g-3 align-items-center">
                      <Col>
                        <Input
                          placeholder="Enter coupon code"
                          type="text"
                          value={couponCode}
                          onChange={(e) => setCouponCode(e.target.value)}
                        />
                      </Col>

                      <Col>
                        <Button className="bg-meme" onClick={() => apply()}>
                          Save
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Collapse>
              </CardBody>
            </div>
          </div>
        </Row>
      </Container> */}
      {/* Fetch all admins */}
      <Container className="mt--2 mx-0 p-4">
        <Row>
          <div className="col-12 col-md-8 col-lg-8 p-0 pr-md-1">
            <div
              style={{
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.06)",
                borderRadius: "4px",
                border: "1px solid rgba(0,0,0,0.125)",
              }}
              ref={ref}
            >
              <div
                className="d-flex align-items-start"
                style={{
                  width: "15px",
                  height: `${isAdminOpen === true ? `${height + "px"}` : "70px"
                    }`,
                  background: " #FAAE99",
                  borderRadius: "4px 0px 0px 4px",
                  position: "absolute",
                }}
              ></div>
              <CardBody
                style={{ backgroundColor: "white", paddingLeft: "30px" }}
              >
                <h6
                  style={{
                    color: "#74798C",
                    marginBottom: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setIsAdminOpen(!isAdminOpen);
                  }}
                >
                  <b>Roles & Permissions</b>
                </h6>
                <Collapse isOpen={isAdminOpen}>
                  <Row>
                    <div className="col">
                      <Card className="shadow">
                        <Table
                          className="align-items-center table-flush"
                          responsive
                        >
                          <thead className="thead-light">
                            <tr>
                              <th scope="col"></th>
                              <th scope="col">Name</th>
                              <th scope="col">Email</th>
                              <th scope="col">Privilege</th>
                              {user.isSuperAdmin === true && (
                                <th scope="col">Remove Admin</th>
                              )}
                              {user.isSuperAdmin === true && (
                                <th scope="col">Permission</th>
                              )}
                              {user.isSuperAdmin === true && (
                                <th scope="col">ReInvite</th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {getAdminReducerDetails?.loading === true ? (
                              <div className="d-flex align-items-center justify-content-center">
                                <ThreeDots
                                  height="20"
                                  width="20"
                                  radius="9"
                                  color="#3107cb"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              </div>
                            ) : (
                              getAdminReducerDetails?.data?.message?.map(
                                (item) => (
                                  <tr key={item._id}>
                                    <td>
                                      <img
                                        src={item.profilePicture}
                                        style={{
                                          borderRadius: "100%",
                                          width: "30px",
                                          height: "30px",
                                          boxShadow:
                                            "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                        }}
                                        className="mx-4"
                                      />
                                    </td>
                                    <td
                                      style={{
                                        color: "black",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          color: "#74798C",
                                        }}
                                        className="font-bold"
                                      >
                                        <h6>{item.name}</h6>
                                        {item.accountConfirmation === true ? (
                                          ""
                                        ) : (
                                          <button
                                            type="button"
                                            style={{
                                              backgroundColor: "#FF4500",
                                              color: "white",
                                              marginLeft: "10px",
                                              fontSize: "12px",
                                              padding: "0px 5px",
                                              borderRadius: "5px",
                                              opacity: "0.8",
                                            }}
                                          >
                                            Pending
                                          </button>
                                        )}
                                      </div>
                                    </td>
                                    <td
                                      style={{
                                        color: "#74798C",
                                      }}
                                    >
                                      <h6>{item.email}</h6>
                                    </td>
                                    <td
                                      style={{
                                        color: "#74798C",
                                      }}
                                    >
                                      <h6>
                                        {item.isSuperAdmin === true
                                          ? "Super Admin"
                                          : "Admin"}
                                      </h6>
                                    </td>
                                    {user.isSuperAdmin === true && (
                                      <td
                                        style={{
                                          color: "#74798C",
                                        }}
                                      >
                                        {item.isSuperAdmin === false ? (
                                          <Button
                                            onClick={() => {
                                              handleDeleteAdmin(item._id);
                                              setDisable(true);
                                            }}
                                            // color='primary'
                                            // className="bg-meme"
                                            style={{
                                              backgroundColor:
                                                "rgba(255, 4, 4, 0.5)",
                                              borderColor:
                                                "rgba(255, 4, 4, 0.5)",
                                              outline: "none !important",
                                              outlineOffset: "none !important",
                                              boxShadow: "none",
                                            }}
                                          >
                                            Delete
                                          </Button>
                                        ) : (
                                          <Button
                                            disabled
                                            style={{
                                              backgroundColor:
                                                "rgba(255, 4, 4, 0.5)",
                                              borderColor:
                                                "rgba(255, 4, 4, 0.5)",
                                              outline: "none !important",
                                              outlineOffset: "none !important",
                                              boxShadow: "none",
                                            }}
                                          >
                                            Delete
                                          </Button>
                                        )}
                                      </td>
                                    )}
                                    {user.isSuperAdmin === true && (
                                      <td>
                                        {item.isSuperAdmin === false ? (
                                          <div className="ml-4">
                                            <FcSettings
                                              size={25}
                                              onClick={() =>
                                                handleNewAdmin(item)
                                              }
                                              style={{ cursor: "pointer" }}
                                            />
                                          </div>
                                        ) : (
                                          <div className="ml-4">
                                            <FcSettings
                                              size={25}
                                              style={{
                                                cursor: "not-allowed",
                                              }}
                                            />
                                          </div>
                                        )}
                                      </td>
                                    )}
                                    {/* REINVITATION BUTTON */}
                                    {user.isSuperAdmin === true && (
                                      <td>
                                        {item.isSuperAdmin === false &&
                                          item.name === "Not onboarded yet" ? (
                                          <Tooltip
                                            title="Invite user again, clicking this would send an email to the user"
                                            placement="top-start"
                                          >
                                            <div style={{ marginLeft: "12px" }}>
                                              <FcInvite
                                                size={25}
                                                onClick={() =>
                                                  reinviteUser(item)
                                                }
                                                style={{ cursor: "pointer" }}
                                              />
                                            </div>
                                          </Tooltip>
                                        ) : item.name !== "Not onboarded yet" &&
                                          item.isSuperAdmin === false ? (
                                          <Tooltip title="Can not invite onboarded user">
                                            <div style={{ marginLeft: "12px" }}>
                                              <FcInvite
                                                size={25}
                                                style={{
                                                  cursor: "not-allowed",
                                                }}
                                              />
                                            </div>
                                          </Tooltip>
                                        ) : (
                                          <Tooltip title="Can not invite yourself">
                                            <div style={{ marginLeft: "12px" }}>
                                              <FcInvite
                                                size={25}
                                                style={{
                                                  cursor: "not-allowed",
                                                }}
                                              />
                                            </div>
                                          </Tooltip>
                                        )}
                                      </td>
                                    )}
                                  </tr>
                                )
                              )
                            )}
                          </tbody>
                        </Table>
                      </Card>
                    </div>
                  </Row>
                </Collapse>
              </CardBody>
            </div>
          </div>
        </Row>
      </Container>
      {/* CHANGE PASSWORD */}
      <Container className="mt--2 mx-0 p-4">
        <Row>
          <div className="col-12 col-md-8 col-lg-8 p-0 pr-md-1 ">
            <div
              style={{
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.06)",
                borderRadius: "4px",
                border: "1px solid rgba(0,0,0,0.125)",
              }}
            >
              <div
                className="d-flex align-items-start"
                style={{
                  width: "15px",
                  height: `${security ? "318px" : "91px"}`,
                  background: " #47BE9B",
                  borderRadius: "4px 0px 0px 4px",
                  position: "absolute",
                }}
              ></div>
              <CardBody
                style={{ backgroundColor: "white", paddingLeft: "30px" }}
              >
                <h6
                  style={{
                    color: "#74798C",
                    marginBottom: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSecurity(!security);
                  }}
                >
                  <b>Forgot/Change Password</b>
                  <p style={{ fontSize: "13px" }}>
                    You can change/update your password from here.
                  </p>
                </h6>
                {error ? (
                  <h1 style={{ color: "red", marginBottom: "10px" }}>
                    {error}
                  </h1>
                ) : (
                  ""
                )}
                <Collapse isOpen={security}>
                  <Row>
                    <div className="col">
                      <FormGroup
                        className="form-group"
                        style={{ marginTop: "10px" }}
                      >
                        <div className="floating-label">
                          <input
                            id="confpassword"
                            className="form-control"
                            type="password"
                            style={{
                              backgroundColor: "#EDF2F7",
                              border: "none",
                            }}
                            name="confpassword"
                            required
                            onChange={(e) => setCurrPassword(e.target.value)}
                            value={currPassword}
                          />
                          <label
                            for="password"
                            style={{
                              color: "#455768",
                              backgroundColor: "#EDF2F7",
                            }}
                          >
                            Current Password
                          </label>
                        </div>
                      </FormGroup>

                      <ValidatedInput
                        label="Password"
                        onChange={handlePasswordChange}
                        onBlur={handlePasswordBlur}
                        onFocus={clearPasswordErrors}
                        value={newPassword}
                        isValid={isPasswordValid}
                        placeholder="Enter New Password"
                        errorMessage={passwordErrorMessage}
                        type="password"
                      />

                      <ValidatedInput
                        label="Confirm Password"
                        onChange={handleConfirmPasswordChange}
                        onBlur={handleConfirmPasswordBlur}
                        onFocus={clearPasswordMatchError}
                        value={confirmPassword}
                        isValid={isPasswordMatch}
                        placeholder="Confirm New Password"
                        errorMessage={confirmPasswordErrorMessage}
                        type="password"
                      />

                      <Button
                        type="submit"
                        // className="bg-meme"
                        onClick={verifyChangePasswordOTP}
                        size="sm"
                        style={{
                          backgroundColor: "#47BE9B",
                          borderColor: "#47BE9B",
                          outline: "none !important",
                          outlineOffset: "none !important",
                          boxShadow: "none",
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                  </Row>
                </Collapse>
              </CardBody>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default EditProfile;
