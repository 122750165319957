import ValidatedInput from "functions/ValidatedInput";
import React, { useEffect, useState } from "react";
import Cards from "@material-tailwind/react/Card";
import Image from "react-bootstrap/Image";
import Heading6 from "@material-tailwind/react/Heading6";
import Privacy from "../../assets/img/PrivacyPolicy/Privacy.svg";
import termsImg from "../../assets/img/illustrations/termsAndService.png";
import resetPass from "../../assets/img/ResetPassword/resetPassword.png";
import "../../styles/ConfirmPassword.css";
import { Modal } from "@material-ui/core";
import { Row, Button, FormGroup } from "reactstrap";
import customToast from "./ToastComponent";
import { sha256 } from "js-sha256";
import { useParams, useLocation } from "react-router-dom";
import { confirmReset } from "network/ApiAxios";
import Countdown, { zeroPad } from "react-countdown";
import { sendOtpToNewEmail } from "network/ApiAxios";
import { newAdmin } from "network/ApiAxios";
import { verifiedOtpThroughToken } from "network/ApiAxios";
import { ToastContainer } from "react-toastify";
import OTPInput from "react-otp-input";
import { AUTH_ROUTE_PATHS } from "routes-constants";
import { ADMIN_ROUTE_PATHS } from "routes-constants";
const ConfirmPassword = (props) => {
  // const { id } = useParams();
  // const sub = useParams();
  // console.log(id, sub, "id");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const token = searchParams.get("token");
  console.log(token, "idd");
  const [email, setEmail] = useState("");
  const [otpScreen, setOtpScreen] = useState(false);
  const [newError, setNewError] = useState("");
  const [error, setError] = useState("");
  const [closeChangePassword, setCloseChangePassword] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(true);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isPasswordMatch, setIsPasswordMatch] = useState(true);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [loadOtp, setLoadOtp] = useState(false);
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] =
    useState("");
  const [loadChangePassword, setLoadChangePassword] = useState(false);
  const [modalChangeOTP, setModalChangeOTP] = useState(false);
  const [OTP, setOTP] = useState("");
  const [timer, setTimer] = useState(Date.now());

  const toggleChangeOTP = () => {
    if (modalChangeOTP) {
      setModalChangeOTP(false);
    }
  };

  useEffect(() => {
    const getDetailsAboutUser = async () => {
      // this api call is used to fetch the details of the user through userId, same is also used for getting the details of admin
      // DON'T GET CONFUSE
      var { data } = await newAdmin(id);
      if (data.message === null) {
        customToast(
          "",
          true,
          "",
          `Error`,
          "Some error occurred, Try again later",
          "Go to Login",
          `${AUTH_ROUTE_PATHS.AUTH_LAYOUT}${AUTH_ROUTE_PATHS.LOGIN}`
        );
      } else {
        // setName(data.message.name);
        setEmail(data.message.email);
      }
    };
    getDetailsAboutUser();
  }, []);

  const renderer = ({ minutes, seconds }) => {
    return (
      <span>
        {zeroPad(minutes)}:{zeroPad(seconds)}
      </span>
    );
  };

  const closeModalChangePassword = async () => {
    if (closeChangePassword) {
      setCloseChangePassword(false);
    }
  };

  const verifyChangePasswordOTP = async () => {
    // set otp screen to true
    try {
      // verifying the entered email
      if (newPassword !== confirmPassword) {
        setNewError("Passwords do not match.");
        setTimeout(() => {
          setNewError("");
        }, 3000);
        setNewPassword("");
        setConfirmPassword("");
      } else if (newPassword.length === 0 || confirmPassword.length === 0) {
        setNewError("Please fill all the fields");
        setTimeout(() => {
          setNewError("");
        }, 3000);
      } else {
        setLoadChangePassword(true);
        // setTimer(Date.now() + 5 * 60 * 1000);
        const res = await sendOtpToNewEmail(email, id);
        setLoadChangePassword(false);
        if (res.data.success == true) {
          // setModalChangeOTP(true);
          setOtpScreen(true);
          setNewError("");
          customToast(
            true,
            "",
            "",
            "OTP sent",
            "Kindly check your registered mail address and enter the OTP",
            "Check Email",
            ""
          );
        } else {
          // setModalChangeOTP(false);
          setOtpScreen(false);
          customToast(
            "",
            true,
            "",
            "Enter correct OTP",
            "Entered OTP is wrong or it has been expired",
            "Go to Dashboard",
            `${ADMIN_ROUTE_PATHS.ADMIN_LAYOUT}${ADMIN_ROUTE_PATHS.INDEX}`
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changePassword = async () => {
    // var sha = sha256(currPassword);
    var sha1 = sha256(newPassword);
    // console.log(sha, "lqq");
    const { data } = await verifiedOtpThroughToken(id, OTP, token, sha1);
    console.log(data, "data");
    if (data.updated == true) {
      if (newPassword.length && confirmPassword.length) {
        setLoadOtp(true);

        setModalChangeOTP(false);
        // const response = await confirmReset(id, sha1);
        // const { data } = response;
        // if (data.success) {
        props.history.push(`${AUTH_ROUTE_PATHS.AUTH_LAYOUT}${AUTH_ROUTE_PATHS.RESET_SUCCESS}`);
        // } else {
        //   setError(data.msg);
        // }
      } else {
        customToast(
          "",
          true,
          "",
          "Please fill all the fields",
          "Fill all the blanks",
          "",
          ""
        );
      }
    } else if (data.expired === true) {
      customToast(
        "",
        true,
        "",
        "Reset Password Link Expired",
        `${data.passwordExpired}`,
        "Generate new link",
        `${AUTH_ROUTE_PATHS.AUTH_LAYOUT}${AUTH_ROUTE_PATHS.RESET_PASSWORD}`
      );
    } else if (data.otpExpired === true) {
      customToast(
        "",
        true,
        "",
        "OTP Expired",
        `${data.message}`,
        "Generate new OTP",
        ""
      );
    } else if (data.invalidOtp === true) {
      customToast(
        "",
        true,
        "",
        "Enter correct OTP",
        `${data.message}`,
        "Invalid otp",
        ""
      );
    } else if (data.passwordPattern === true) {
      customToast(
        "",
        true,
        "",
        "Password Pattern wrong",
        `Enter correct format password`,
        "Go To Reset Password",
        `${AUTH_ROUTE_PATHS.AUTH_LAYOUT}${AUTH_ROUTE_PATHS.RESET_PASSWORD}`
      );
    } else {
      customToast(
        "",
        true,
        "",
        "Something went wrong",
        `Try again later`,
        "Go To Reset Password",
        `${AUTH_ROUTE_PATHS.AUTH_LAYOUT}${AUTH_ROUTE_PATHS.RESET_PASSWORD}`
      );
    }
  };

  const handlePasswordChange = (e) => {
    const newPass = e.target.value;
    setNewPassword(newPass);

    // Password validation rules
    const hasValidLength = newPass.length >= 8;
    const hasAlphabet = /[a-zA-Z]/.test(newPass);
    const hasNumber = /[0-9]/.test(newPass);
    const hasSpecialChar = /[!@#%^&*()_+{}\[\]:;<>,.?~\-\/\\]/.test(newPass);
    const hasNoDollar = !/\$/.test(newPass); // Excluding $
    const isValid =
      hasValidLength &&
      hasAlphabet &&
      hasNumber &&
      hasSpecialChar &&
      hasNoDollar;
    setIsPasswordValid(isValid);
    setPasswordErrorMessage(
      isValid
        ? ""
        : newPassword.length === 0
          ? ""
          : "Password must be 8+ chars with num & special symbol."
    );
  };

  const handlePasswordBlur = () => {
    // Password validation rules
    const hasValidLength = newPassword.length >= 8;
    const hasAlphabet = /[a-zA-Z]/.test(newPassword);
    const hasNumber = /[0-9]/.test(newPassword);
    const hasSpecialChar = /[!@#%^&*()_+{}\[\]:;<>,.?~\-\/\\]/.test(
      newPassword
    );
    const hasNoDollar = !/\$/.test(newPassword); // Excluding $
    setIsPasswordValid(
      hasValidLength &&
      hasAlphabet &&
      hasNumber &&
      hasSpecialChar &&
      hasNoDollar
    );
    setPasswordErrorMessage(
      isPasswordValid
        ? ""
        : newPassword.length === 0
          ? ""
          : "Password must be 8+ chars with num & special symbol."
    );
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPass = e.target.value;
    setConfirmPassword(newConfirmPass);

    // Compare with the original password
    const isValid = newConfirmPass === newPassword;

    setIsPasswordMatch(isValid);
    setConfirmPasswordErrorMessage(isValid ? "" : "Passwords do not match");
  };

  const handleConfirmPasswordBlur = () => {
    setIsPasswordMatch(confirmPassword === newPassword);
    if (isPasswordMatch) {
      setConfirmPasswordErrorMessage("");
    } else {
      setConfirmPasswordErrorMessage("Passwords do not match");
    }
  };
  const clearPasswordErrors = () => {
    setIsPasswordValid(true);
  };

  const clearPasswordMatchError = () => {
    setIsPasswordMatch(true);
  };

  const handleChangeOTP = (emailOtp) => {
    setOTP(emailOtp);
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* OLD UI MODAL */}
      {/* <Modal
        style={{
          width: "25%",
          marginLeft: "40%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        // open={modalChangeOTP}
        onClose={() => toggleChangeOTP()}
      >
        <Cards>
          <div className="d-flex flex-col">
            <h1 className="text-black mb-1">
              Enter the OTP sent on <b>{email}</b>.
            </h1>

            <FormGroup className="form-group" style={{ marginTop: "10px" }}>
              <div className="floating-label">
                <input
                  id="outlined-size-normal"
                  className="form-control"
                  type="number"
                  style={{
                    backgroundColor: "#EDF2F7",
                    border: "none",
                  }}
                  name="otp"
                  required
                  onChange={(e) => setOTP(e.target.value)}
                  value={OTP}
                />
                <label
                  for="otp"
                  style={{
                    color: "#455768",
                    backgroundColor: "#EDF2F7",
                  }}
                >
                  OTP
                </label>
              </div>
            </FormGroup>
            <div className="d-flex">
              <Button
                size="sm"
                style={{
                  backgroundColor: "#3107cb",
                  borderColor: "#3107cb",
                  outline: "none !important",
                  outlineOffset: "none !important",
                  boxShadow: "none",
                  cursor: loadOtp ? "not-allowed" : "",
                }}
                onClick={changePassword}
                disabled={loadOtp}
              >
                Submit
              </Button>
              <Button
                size="sm"
                style={{
                  backgroundColor: "#A6AEAB",
                  borderColor: "#A6AEAB",
                  outline: "none !important",
                  outlineOffset: "none !important",
                  boxShadow: "none",
                }}
                onClick={() => toggleChangeOTP()}
              >
                Cancel
              </Button>
            </div>
            <div className="d-flex align-items-center justify-content-end text-black">
              <p className="">Time Remaining: </p>{" "}
              <Countdown
                date={timer}
                renderer={renderer}
                zeroPadTime={2}
                autoStart={true}
                key={timer}
              />
            </div>
          </div>
        </Cards>
      </Modal> */}
      <div className="d-flex flex-col align-items-center justify-content-center w-100 " style={{ backgroundColor: "#ccffcc", position: "relative", overflow: "hidden" , height: "100vh"}}>

        {/* <div className="fixed smallGreenCircle d-none d-lg-block "></div> */}
        {/* <div className="absolute top-32 -left-4 w-72 h-72 bg-purple-400 rounded-full mix-blend-multiply filter blur-3xl opacity-70 animate-blob"></div> */}
        <div className="fixed loginGreenCircle d-none d-lg-block "></div>
        {/* <div className="fixed loginGreenCircleHollow d-none d-lg-block "></div> */}
        <div
          style={{
            marginTop: otpScreen ? "-1.5rem" : "0",
            width: "35%",
            // marginLeft: "35%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: "999999",
          }}
        // open={openChangePassword}
        // onClose={() => closeModalChangePassword()}
        // aria-labelledby="parent-modal-title"
        // aria-describedby="parent-modal-description"
        >
          <div
            style={{
              // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.06)",
              width: "auto",
              height: "100%",
              borderRadius: "23px",
              backgroundColor: "#fff",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              // overflow: "hidden",
              // boxShadow:
              // "0px 5px 5px 0px rgba(0, 0, 0, 0.25), 0px 5px 5px 0px #FFF",
              // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              boxShadow:
                "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
              marginBottom: "5px",
            }}
          // className="px-md-4"
          >
            <div className=" resetPass">
              <img
                src={resetPass}
                style={{
                  objectFit: "cover",
                  width: "100%",
                }}
              />
              {/* <div
              style={
                {
                  // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.06)",
                  // borderRadius: "0px 0px 25px 25px",
                }
              }
            ></div> */}
            </div>

            <Row>
              <div className="flex-col mt-2 d-flex align-items-center justify-content-center w-full">
                <h6
                  style={{ color: "#323250", letterSpacing: "1px", fontSize: "20px" }}
                  className="mb-4"
                >
                  Reset your password
                </h6>
                <div className="d-flex flex-col w-full md:px-4 px-6 resetPx">
                  <label
                    style={{
                      color: "#455768",
                      display: "flex",
                      fontSize: "13px",
                      fontWeight: "400",
                    }}
                  >
                    <strong>Enter Password</strong>
                  </label>
                  <ValidatedInput
                    label="Password"
                    onChange={handlePasswordChange}
                    onBlur={handlePasswordBlur}
                    onFocus={clearPasswordErrors}
                    value={newPassword}
                    isValid={isPasswordValid}
                    placeholder="Enter New Password"
                    errorMessage={passwordErrorMessage}
                    type="password"
                  />
                </div>
                <div className="d-flex flex-col w-full md:px-4 px-6 resetPx">
                  <label
                    style={{
                      color: "#455768",
                      display: "flex",
                      fontSize: "13px",
                      fontWeight: "400",
                    }}
                  >
                    <strong>Confirm Password</strong>
                  </label>
                  <ValidatedInput
                    label="Confirm Password"
                    onChange={handleConfirmPasswordChange}
                    onBlur={handleConfirmPasswordBlur}
                    onFocus={clearPasswordMatchError}
                    value={confirmPassword}
                    isValid={isPasswordMatch}
                    placeholder="Confirm New Password"
                    errorMessage={confirmPasswordErrorMessage}
                    type="password"
                  />
                </div>

                <div className="w-full md:px-4 px-6 resetPx">
                  <Button
                    type="submit"
                    // className="bg-meme"
                    onClick={verifyChangePasswordOTP}
                    size="sm"
                    style={{
                      backgroundColor: "#3107cb",
                      borderColor: "#3107cb",
                      outline: "none !important",
                      outlineOffset: "none !important",
                      boxShadow: "none",
                      cursor:
                        !newPassword ||
                          !confirmPassword ||
                          loadChangePassword ||
                          passwordErrorMessage.length > 0 ||
                          confirmPasswordErrorMessage.length > 0
                          ? "not-allowed"
                          : "",
                      width: "100%",
                    }}
                    disabled={
                      !newPassword ||
                      !confirmPassword ||
                      loadChangePassword ||
                      passwordErrorMessage.length > 0 ||
                      confirmPasswordErrorMessage.length > 0
                    }
                    className={otpScreen ? "py-2 mb-2" : "py-2 mb-4"}
                  >
                    Update Password
                  </Button>
                  {otpScreen && (
                    <div className="d-flex flex-col align-items-center justify-content-center px-2 py-2">
                      <hr className="hr2 my-2" />
                      <p
                        className="mb-2"
                        style={{ color: "#455768", fontSize: "13px" }}
                      >
                        Enter the otp sent on mail
                      </p>
                      <OTPInput
                        value={OTP}
                        onChange={handleChangeOTP}
                        numInputs={6}
                        renderSeparator={<span></span>}
                        inputStyle="inputStyle"
                        renderInput={(props) => <input {...props} />}
                        style={{ color: "black" }}
                      />
                      <Button
                        type="submit"
                        // className="bg-meme"
                        size="sm"
                        style={{
                          backgroundColor: "#3107cb",
                          borderColor: "#3107cb",
                          outline: "none !important",
                          outlineOffset: "none !important",
                          boxShadow: "none",
                          cursor: loadOtp || !OTP ? "not-allowed" : "",
                        }}
                        onClick={changePassword}
                        disabled={loadOtp || !OTP}
                        className="px-4 py-2 my-2"
                      >
                        Verify OTP
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmPassword;
