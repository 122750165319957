import { useState, useRef, useEffect, useCallback } from "react"
import { FaQuestionCircle, FaCreditCard, FaTimesCircle, FaMoneyBillWave, FaChevronUp } from "react-icons/fa"
import "../../assets/css/FAQ2.css"
import memeLogo from "../../assets/img/icons/common/MeMeOg.png";


const FAQ = () => {
  const [showModal, setShowModal] = useState(false)
  const [activeAccordion, setActiveAccordion] = useState(null)
  const faqRef = useRef(null)

  const handleClickOutside = useCallback(
    (event) => {
      if (faqRef.current && !faqRef.current.contains(event.target)) {
        setShowModal(false);
        setActiveAccordion(null);
      }
    },
    [faqRef]
  );

  useEffect(() => {
    if (showModal) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showModal, handleClickOutside]);
  // Added handleClickOutside to dependencies

  const toggleModal = () => setShowModal(!showModal)

  const toggleAccordion = (key) => {
    setActiveAccordion(activeAccordion === key ? null : key)
  }

  return (
    <div className="faq-container">
      <button onClick={toggleModal} className="faq-button">
        <FaQuestionCircle size={24} />
      </button>

      {showModal && (
        <div className={`faq-modal ${showModal ? "active" : ""}`}>
          <div ref={faqRef} className="faq-modal-content">
            <div className="faq-modal-header">
              {/* <h2 className="font-bold">FAQ</h2> */}
              <div className="faq-header-text">
                <h2>👋 Hi there!</h2>
                <p>Welcome to Arishti Support! We're ready to help you 🚀</p>
              </div>
              <button className="faq-close-button" onClick={toggleModal}>
                <FaTimesCircle size={24} />
              </button>
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#2E07BF" fill-opacity="1" d="M0,224L80,218.7C160,213,320,203,480,208C640,213,800,235,960,234.7C1120,235,1280,213,1360,202.7L1440,192L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path></svg>
            </div>
            <div className="faq-modal-body">
              <div className="faq-accordion">
              {faqData.map((item) => (
                  <div key={item.id} className="faq-accordion-item">
                    <button
                      className={`faq-accordion-header ${activeAccordion === item.id ? "active" : ""}`}
                      onClick={() => toggleAccordion(item.id)}
                    >
                      <div className="d-flex justify-content-between  align-items-center">
                      {item.icon}
                      {item.question}
                      </div>
                      {item.arrow}
                    </button>
                    <div className={`faq-accordion-content ${activeAccordion === item.id ? "active" : ""}`}>
                      <p>{item.answer}</p>
                    </div>
                  </div>
                ))}
                </div>
            </div>
          <div className="faq-modal-footer">
            <h6 className="text-black text-bold">Powered by</h6>
            <img src={memeLogo} alt="MeMe" style={{
                  width: "80px",
                  height: "20px",
                }} />
          </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default FAQ;
const faqData = [
  {
    id: "0",
    icon: <FaCreditCard className="faq-icon" />,
    arrow:<FaChevronUp className="faq-accordion-arrow"/>,
    question: "How will new members be billed?",
    answer:
      "We'll make a prorated charge to your credit card to cover your new team member's account for the remainder of the current billing period. As an example, suppose your team is on the Pro subscription, which costs ₹655 per active user per month when billed monthly. You add a new team member ten days into your billing period, and there are 20 remaining days in the month. The per-day cost for each user is ₹655/30, so we will make a one-time charge of ₹436.67 (₹655/30 days * 20 days) to your team's credit card on file.",
  },
  {
    id: "1",
    arrow:<FaChevronUp className="faq-accordion-arrow"/>,
    icon: <FaTimesCircle className="faq-icon" />,
    question: "How can I cancel my subscription? Will I get a refund?",
    answer: "Sorry, we don't process any refunds as of now.",
  },
  {
    id: "2",
    arrow:<FaChevronUp className="faq-accordion-arrow"/>,
    icon: <FaMoneyBillWave className="faq-icon" />,
    question: "What are the available payment options?",
    answer: "We only support payments through card as of now.",
  },
]