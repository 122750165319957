import Tooltip from "@material-ui/core/Tooltip";
import { fetchInactiveSessionData } from "network/ApiAxios";
import { activeSessionDate } from "network/ApiAxios";
import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import { Button, Card, CardHeader, Container, Row, Table } from "reactstrap";
import Header from "../../components/Headers/Header";
import { useDispatch, useSelector } from "react-redux";
import { getAuditActions } from "components/redux/adminActions";
import { RotatingLines } from "react-loader-spinner";
import { Modal } from "reactstrap";
import { Radio } from "@material-ui/core";
import { exportAuditData } from "network/ApiAxios";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { istConversion } from "functions/timeConversion";
import { generateFileName } from "functions/generateFileName";
import customToast from "./ToastComponent";
import Paginate from "views/ReusableComponents/Paginate";
import { MdCancel } from "react-icons/md";
import axios from "axios";

const AuditSession = () => {
  // console.log(`UA: ${window.navigator.userAgent}`);
  const [ip, setIp] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [value, setValue] = useState([]);
  const [openExport, setOpenExport] = useState(false);
  const [loadExport, setLoadExport] = useState(false);
  const [selectedFormat, setSelectedFormat] = useState("");
  const [selectDuration, setSelectDuration] = useState("");
  const [userId, setUserId] = useState([]);
  const [currPage, setCurrPage] = useState(0);
  const toggleExport = () => {
    setOpenExport(!openExport);
    setSelectDuration("");
    setSelectedFormat("");
  };
  const DATE_OPTIONS = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const orgToken = JSON.parse(localStorage.getItem("user"))?.orgToken;
  const loggedInUserName = JSON.parse(localStorage.getItem("user"))?.name;
  const superAdmin = JSON.parse(localStorage.getItem("user"))?.isSuperAdmin;
  const loginTime = JSON.parse(localStorage.getItem("loginTime"));
  const clientIp = JSON.parse(localStorage.getItem("ip"));
  const dispatch = useDispatch();
  const audit = useSelector((state) => state.getAuditReducer.auditData);
  const loading = useSelector((state) => state.getAuditReducer.loading);
  const auditDetails = useSelector((state) => state.getAuditReducer);
  const headerDetails = useSelector((state) => state.getDataReducer);

  let val;
  let ids;
  const activeSession = async () => {
    const id = JSON.parse(localStorage.getItem("user"))?._id;

    if (id) {
      var res = await activeSessionDate(id);
      ids = res?.message?.map((item) => item.userId);
      setUserId(ids);
      val = res?.data?.data?.map((i) => i.date);
      setValue(val);
    }
  };
  useEffect(() => {
    const f = async () => {
      try {
        if (process.env.REACT_APP_IP_LOCATION === "true") {
          const response = await axios.post("https://ipapi.co/json/");
          // console.log(response, "ip details");

          if (response.data.error) {
            const resp = await axios.get(
              `http://api.positionstack.com/v1/reverse?access_key=${process.env.REACT_APP_POSITION_STACK_API_KEY}&query=${clientIp}`
            );
            console.log(resp, "second api");
            setIp(clientIp);
            setCity(resp.data.data[0].county);
            setCountry(resp.data.data[0].country);
          } else {
            // If the first API call is successful, return its data

            setCity(response.data?.city);
            setCountry(response.data?.country_name);
            setIp(clientIp);
          }
        } else {
          setIp("NOT AVAILABLE");
          setCity("NOT AVAILABLE");
          setCountry(null);
        }
      } catch (error) {
        console.error("Error fetching IP details:", error);

        // Check if the error is due to rate limiting (status code 429)
        if (error.response && error.response.status === 429) {
          // If rate-limited, try the second API
          try {
            if (process.env.REACT_APP_IP_LOCATION === "true") {
              const resp = await axios.get(
                `http://api.positionstack.com/v1/reverse?access_key=${process.env.REACT_APP_POSITION_STACK_API_KEY}&query=${clientIp}`
              );
              console.log(resp.data.data, "second api after rate limit");
              setIp(clientIp);
              setCity(resp.data.data[0].county);
              setCountry(resp.data.data[0].country);
            } else {
              setIp("NOT AVAILABLE");
              setCity("NOT AVAILABLE");
              setCountry(null);
            }
          } catch (secondApiError) {
            console.error(
              "Error fetching IP details from the second API:",
              secondApiError
            );
          }
        } else {
          setCity(null);
          setCountry(null);
        }
      }
    };
    f();
    activeSession();
  }, []);

  useEffect(() => {
    const orgToken = JSON.parse(localStorage.getItem("user"))?.orgToken;
    dispatch(getAuditActions(orgToken));
  }, []);

  const downloadExportedData = async () => {
    try {
      setLoadExport(true);
      const data = await exportAuditData(orgToken, selectDuration);
      console.log(data, "export");
      if (data.length === 0) {
        customToast(
          true,
          "",
          "",
          `Data do not exists`,
          "Data will be generated when you logout and login.",
          "",
          ""
        );
      }
      setLoadExport(false);
      const fileName = generateFileName(selectDuration);
      if (selectedFormat === "pdf") {
        const doc = new jsPDF();
        const headerText = "MessageMe Platform Logged In Details Report";

        doc.text(headerText, doc.internal.pageSize.width / 2, 15, "center");

        const keyValues = [
          { key: "Organization Name", value: loggedInUserName },
          { key: "Organization Token", value: orgToken },
          { key: "Log Generated at", value: istConversion(new Date()) },
          {
            key: "Log Generated by",
            value:
              superAdmin === true
                ? loggedInUserName + " (Super Admin)"
                : loggedInUserName + " (Admin)",
          },
          {
            key: "Total Number of Users",
            value: headerDetails.data.users.toString(),
          },
          { key: "Log Generated IP", value: clientIp },
        ];

        doc.setFillColor(200, 200, 200); // Greyish background color
        let yPos = 10;

        for (const { key, value } of keyValues) {
          doc.setFont("bold");
          doc.setFontSize(12);
          doc.setFillColor(200, 200, 200); // Greyish background color

          doc.setDrawColor(0);

          doc.rect(15, yPos + 10, 180, 10, "F");
          doc.setTextColor(0, 0, 0);
          doc.text(`${key}:`, 16, yPos + 16);
          doc.setTextColor(100, 100, 100);
          const keyWidth = doc.getTextWidth(`${key}:`);
          doc.text(`${value}`, 18 + keyWidth, yPos + 16);

          yPos += 10;
        }
        yPos += 5;
        const columns = [
          "Name",
          "IP Address",
          "User Agent",
          "Location",
          "Logged In Date",
          "Logged Out Date",
        ];

        const rows = data.map((record) => [
          record.name,
          record.clientIp,
          record.userAgent,
          record.location,
          istConversion(record.loginDate),
          istConversion(record.logoutDate),
        ]);

        doc.autoTable({
          startY: yPos + 10,
          head: [columns],
          body: rows,
        });

        doc.save(`${fileName}.pdf`);
      } else if (selectedFormat === "excel") {
        const filteredData = data.map((record) => ({
          Name: record.name,
          "IP Address": record.clientIp,
          "User Agent": record.userAgent,
          Location: record.location,
          "Logged In Date": istConversion(record.loginDate),
          "Logged Out Date": istConversion(record.logoutDate),
        }));

        const ws = XLSX.utils.json_to_sheet(filteredData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sessions");

        XLSX.writeFile(wb, `${fileName}.xlsx`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrPage(selectedPage);
  };

  const PER_PAGE = 10;
  const offset = currPage * PER_PAGE;
  const pageCount = Math.ceil(audit.length / PER_PAGE);

  return (
    <>
      <Header />
      <Modal isOpen={openExport} toggle={toggleExport} centered size="md">
        <Card
          style={{
            width: "600px",
            height: "auto",
            boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
            borderRadius: "15px",
            backgroundColor: "#F6F7F9",
            padding: "15px",
          }}
        >
          <div className="d-flex align-items-center justify-content-between mb-2">
            <h6 className="font-bold text-black">
              Exporting the activity logs in PDF format
            </h6>
            <MdCancel
              color="#d3d3d3"
              className="font-bold cursor-pointer"
              size={20}
              onClick={toggleExport}
            />
          </div>
          <div className="d-flex flex-col">
            <p className="font-bold text-black">Type of report</p>
            <div
              className="d-flex align-items-center text-black justify-content-between"
              style={{ width: "44%" }}
            >
              <div className="d-flex align-items-center">
                <Radio
                  color="primary"
                  id="react"
                  name="basic"
                  label="React"
                  value={"pdf"}
                  checked={selectedFormat === "pdf"}
                  onChange={(e) => {
                    setSelectedFormat(e.target.value);
                  }}
                />
                <p style={{ fontSize: "13px", fontWeight: "400" }}>PDF</p>
              </div>
              <div className="d-flex align-items-center">
                <Radio
                  color="primary"
                  id="react"
                  name="excel"
                  label="React"
                  value={"excel"}
                  checked={selectedFormat === "excel"}
                  onChange={(e) => {
                    setSelectedFormat(e.target.value);
                  }}
                />
                <p style={{ fontSize: "13px", fontWeight: "400" }}>Excel</p>
              </div>
            </div>
            <p className="font-bold text-black">Select the Duration</p>
            <div
              className="d-flex align-items-center text-black justify-content-between"
              style={{ width: "49.5%" }}
            >
              <div className="d-flex align-items-center">
                <Radio
                  color="primary"
                  id="react"
                  name="basic"
                  label="React"
                  value={"today"}
                  checked={selectDuration === "today"}
                  onChange={(e) => {
                    setSelectDuration(e.target.value);
                  }}
                />
                <p style={{ fontSize: "13px", fontWeight: "400" }}>Today</p>
              </div>
              <div className="d-flex align-items-center ">
                <Radio
                  color="primary"
                  id="react"
                  name="basic"
                  label="React"
                  value={"month"}
                  checked={selectDuration === "month"}
                  onChange={(e) => {
                    setSelectDuration(e.target.value);
                  }}
                />
                <p style={{ fontSize: "13px", fontWeight: "400" }}>
                  This Month
                </p>
              </div>
            </div>
            <div
              className="d-flex align-items-center text-black justify-content-between"
              style={{ width: "71.7%" }}
            >
              <div className="d-flex align-items-center">
                <Radio
                  color="primary"
                  id="react"
                  name="basic"
                  label="React"
                  value={"week"}
                  checked={selectDuration === "week"}
                  onChange={(e) => {
                    setSelectDuration(e.target.value);
                  }}
                />
                <p style={{ fontSize: "13px", fontWeight: "400" }}>This Week</p>
              </div>
              <div className="d-flex align-items-center">
                <Radio
                  color="primary"
                  id="react"
                  name="basic"
                  label="React"
                  value={"sixtyDays"}
                  checked={selectDuration === "sixtyDays"}
                  onChange={(e) => {
                    setSelectDuration(e.target.value);
                  }}
                />
                <p style={{ fontSize: "13px", fontWeight: "400" }}>
                  Last 60 transactions/Activity logs
                </p>
              </div>
            </div>

            <div className="d-flex flex-col mt-2">
              <p className="text-black font-bold">
                The exported report would consist below columns:{" "}
              </p>
              <p
                className="text-black ml-2"
                style={{ fontWeight: "400", fontSize: "13px" }}
              >
                Name, IP Address, Date, Agent(Browser), Location of the admin
                user
              </p>
            </div>
            <div className="d-flex justify-content-end">
              {loadExport === true ? (
                <Button
                  style={{
                    backgroundColor: " #d3d3d3",
                    borderRadius: "10px",
                    color: "black",
                    borderColor: "#d3d3d3",
                    outline: "none !important",
                    outlineOffset: "none !important",
                    boxShadow: "none",
                    marginRight: "20px",
                  }}
                >
                  <RotatingLines
                    strokeColor="black"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="20"
                    height="20"
                    visible={true}
                  />
                </Button>
              ) : (
                <Button
                  style={{
                    backgroundColor: " #d3d3d3",
                    borderRadius: "10px",
                    color: "black",
                    borderColor: "#d3d3d3",
                    outline: "none !important",
                    outlineOffset: "none !important",
                    boxShadow: "none",
                    marginRight: "20px",
                  }}
                  onClick={downloadExportedData}
                >
                  Export
                </Button>
              )}
            </div>
          </div>
        </Card>
      </Modal>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "22px",
          marginTop: "22px",
        }}
      >
        <h6 style={{ color: "black" }}>Sessions</h6>
        <p style={{ color: "grey", marginBottom: "5px" }}>
          For audit purpose, <b>MessageMe™ </b>stores the recent 60 logged user
          details
        </p>
        <h6 style={{ color: "black" }}>Active Session</h6>
      </div>

      <div style={{ margin: "22px" }}>
        <Row>
          <div className="col">
            <Card className="shadow">
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">ClientIP</th>
                    <th scope="col">Logged In</th>
                    <th scope="col">User Agent</th>
                    <th scope="col">Location</th>
                  </tr>
                </thead>
                <tbody style={{ color: "black" }}>
                  <td>{ip}</td>
                  <Tooltip title={istConversion(loginTime)}>
                    <td>
                      {/* {new Date().toLocaleDateString("en-US", DATE_OPTIONS)} */}
                      {/* {value[0]?.split("T")[0].split("-").reverse().join("/")} */}

                      {istConversion(loginTime).trim().substring(0, 20) +
                        "...."}
                    </td>
                  </Tooltip>
                  <td>
                    <Tooltip title={window.navigator.userAgent}>
                      <span>
                        {window.navigator.userAgent.trim().substring(0, 42) +
                          "...."}
                      </span>
                    </Tooltip>
                  </td>
                  <td>
                    {city}, {country}
                  </td>
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </div>

      {/* INACTIVE SESSION MAPPING */}
      <div
        style={{
          marginLeft: "22px",
          marginTop: "22px",
        }}
        className="d-flex align-items-center justify-content-between"
      >
        <div className="d-flex flex-col">
          <h6 style={{ color: "black" }}>Inactive Sessions(60 most recent)</h6>
          <p style={{ color: "grey", marginBottom: "5px" }}>
            For audit purpose, <b>MessageMe™ </b>stores the recent 60 logged
            user details
          </p>
        </div>
        {loadExport === true ? (
          <Button
            style={{
              backgroundColor: " #d3d3d3",
              borderRadius: "10px",
              color: "black",
              borderColor: "#d3d3d3",
              outline: "none !important",
              outlineOffset: "none !important",
              boxShadow: "none",
              marginRight: "20px",
            }}
          >
            <RotatingLines
              strokeColor="black"
              strokeWidth="5"
              animationDuration="0.75"
              width="20"
              height="20"
              visible={true}
            />
          </Button>
        ) : (
          <Button
            style={{
              backgroundColor: " #d3d3d3",
              borderRadius: "10px",
              color: "black",
              borderColor: "#d3d3d3",
              outline: "none !important",
              outlineOffset: "none !important",
              boxShadow: "none",
              marginRight: "20px",
            }}
            onClick={toggleExport}
          >
            Export
          </Button>
        )}
      </div>

      <div
        style={{
          margin: "22px",

          // marginBottom: "200px"
        }}
      >
        {loading === true ? (
          <div className="d-flex align-items-center justify-content-center">
            <RotatingLines
              strokeColor="black"
              strokeWidth="5"
              animationDuration="0.75"
              width="50"
              height="50"
              visible={true}
            />
          </div>
        ) : (
          <Row>
            <div className="col">
              <Card className="shadow">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">Name</th>
                      <th scope="col">ClientIP</th>
                      <th scope="col">Logged In</th>
                      <th scope="col">User Agent</th>
                      <th scope="col">Location</th>
                    </tr>
                  </thead>
                  <tbody style={{ color: "black" }}>
                    {audit?.slice(offset, offset + PER_PAGE)?.map((item) => (
                      <tr key={item._id}>
                        <td
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Image
                            src={item.profilePicture}
                            roundedCircle
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                            }}
                          />
                        </td>

                        <td>{item.name}</td>
                        <td>{item.clientIp}</td>

                        <Tooltip title={istConversion(item.loginDate)}>
                          <td>
                            {istConversion(item.loginDate)
                              .trim()
                              .substring(0, 20) + "...."}
                          </td>
                        </Tooltip>

                        <Tooltip title={item.userAgent}>
                          <td>
                            {item.userAgent.trim().substring(0, 42) + "...."}
                          </td>
                        </Tooltip>

                        <td>
                          {item.location}, {item.countryName}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card>
            </div>
          </Row>
        )}
      </div>
      {!loading && audit.length > 9 && (
        <Paginate pageCount={pageCount} handlePageClick={handlePageClick} />
      )}
    </>
  );
};

export default AuditSession;
