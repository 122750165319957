/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";

// reactstrap components
import { Button, FormGroup, Form } from "reactstrap";
import { forgotPassword } from "../../network/ApiAxios";

import config from "../../config";
import forgetpass from "../../assets/ForgetPass.png";
import memeLogo from "../../assets/img/icons/common/memeLogo.png";

import "../../assets/css/bootstrap.css";
import "../../assets/css/login.css";
import { ToastContainer } from "react-toastify";
import customToast from "./ToastComponent";
import ValidatedInput from "functions/ValidatedInput";
import { AUTH_ROUTE_PATHS } from "routes-constants";

const ResetPassword = (props) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [toastMessage, setToastMessage] = useState(
    "Email sent! Check it to reset your password."
  );
  const [userID, setUserID] = useState(null);

  const sendEmail = async () => {
    const response = await forgotPassword(email);
    const { data } = response;
    try {
      if (data.success) {
        customToast(
          "",
          "",
          "",
          `Link Sent`,
          "Check your registered email for link",
          "",
          "",
          true
        );
        setTimeout(() => {
          props.history.push(`${AUTH_ROUTE_PATHS.CONFIRM_PASSOWRD}/${data.id}`);
        }, 10000);

        console.log(data);

        console.log(data);
        if (config.DEMO) {
          setToastMessage(
            "This is a demo, so we will not send you an email. Instead, click on this link to reset your password:"
          );
          setUserID(data.userID);
        }
        setShowToast(true);
      } else {
        customToast(
          "",
          true,
          "",
          `Failed`,
          "Reset password link was not sent, try again",
          "",
          ""
        );
        setError(data.errors[0].msg);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleClick = (event) => {
    event.preventDefault();
    sendEmail();
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    // Email validation rules
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValid = emailPattern.test(newEmail);
    setIsEmailValid(isValid);
    setEmailErrorMessage(isValid ? "" : "Invalid email format.");
  };

  const handleEmailBlur = () => {
    // Email validation rules
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setIsEmailValid(emailPattern.test(email));
    if (isEmailValid) {
      setEmailErrorMessage("");
    } else {
      setEmailErrorMessage("Invalid email format.");
    }
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <section
        style={{ overflow: "hidden", position: "relative", height: "100vh", width: "100vw", backgroundColor: "#e6f2ff" }}
        className="d-flex flex-col align-items-center justify-content-center"
      >
        <div className="fixed loginGreenCircle d-none d-lg-block "></div>
        <div className="fixed loginGreenCircleHollow d-none d-lg-block "></div>
        <div className="d-flex flex-row justify-content-center align-items-center card shadow-xl cardShadow"
          style={{ width: "60vw", borderRadius: "20px", height: "70vh", zIndex: "3", backgroundColor: "#ffffff" }}>
          <div className="d-none d-lg-block p-0 w-50 rounded-start" style={{ height: "70vh" }}>
            <img src={forgetpass} alt="registration" className="h-100 w-100 " style={{ borderRadius: "15px 0 0px 15px" }} />
          </div>
          <div className="h-100 w-lg-50 responsive-div">
            <div className="d-flex flex-col justify-content-center align-items-center mt-5">
            <h5 className="h3heading text-center  mb-5">
              Reset Password
            </h5>
              <img
                src={memeLogo}
                alt="logo"
                style={{
                  top: "20px",
                  position: "absolute",
                  right: "2px",
                  paddingRight: "20px",
                  width: "166.6px",
                  height: "40.45px",
                }}
              />
              <Form
                role="form"
                 className="w-100"
                onSubmit={(values) => {
                  handleClick(values);
                }}
              >
                <label style={{ color: "#585858", display: "flex" }}>
                  <strong>E-mail</strong>
                </label>
                <ValidatedInput
                  label="Email"
                  onChange={handleEmailChange}
                  onBlur={handleEmailBlur}
                  onFocus={() => setIsEmailValid(true)}
                  value={email}
                  isValid={isEmailValid}
                  placeholder="Enter your email"
                  errorMessage={emailErrorMessage}
                  type="email"
                />
                <Button
                  className={`${emailErrorMessage.length > 0 || email.length === 0
                    ? "cursor-not-allowed signIn"
                    : "signIn"
                    }`}
                  type="submit"
                  disabled={
                    emailErrorMessage.length > 0 || email.length === 0
                  }
                >
                  Reset Password
                </Button>
              </Form>
            </div>
          </div>
          {/* </div> */}
          {/* </div> */}
          {/* </d..iv> */}
          {/* </div> */}
        </div>
      </section>
    </>
  );
};

export default ResetPassword;

// <>
// <div
//     aria-live="polite"
//     aria-atomic="true"
//     style={{
//         position: 'fixed',
//         minHeight: '100px',
//         width: "35%",
//         right: 10,
//         bottom: 100,
//         zIndex: 50
//     }}
// >
//     <Toast style={{
//         position: 'absolute',
//         top: 0,
//         right: 0,
//         backgroundColor: "white",
//         padding: 10,
//         borderRadius: 10
//     }} onClose={() => setShowToast(false)} show={showToast} delay={5000} autohide={!config.DEMO}>
//         <Toast.Header>
//             <img style={{height: "30px", width: "100px"}} src={require("assets/img/brand/argon-react.png").default}  alt="..."/>
//         </Toast.Header>
//         <Toast.Body>
//             {toastMessage}
//             {config.DEMO ?
//                 //<a href={config.DOMAIN_NAME + '/auth/confirm-password/' + userID}>
//                     {config.DOMAIN_NAME + '/auth/confirm-password/' + userID}
//                 </a> : null}
//         </Toast.Body>
//     </Toast>
// </div>
// <Col lg="5" md="7">
//     <Card className="bg-secondary shadow border-0">
//         <CardBody className="px-lg-5 py-lg-5">
//             <Form role="form">
//                 <FormGroup className="mb-3">
//                     <InputGroup className="input-group-alternative">
//                         <InputGroupAddon addonType="prepend">
//                             <InputGroupText>
//                                 <i className="ni ni-email-83"/>
//                             </InputGroupText>
//                         </InputGroupAddon>
//                         <Input placeholder="Email" type="email" autoComplete="email" value={email}
//                                onChange={e => setEmail(e.target.value)}/>
//                     </InputGroup>
//                 </FormGroup>
//                 {error ?
//                     <div className="text-muted font-italic">
//                         <small>
//                             error:{" "}
//                             <span className="text-red font-weight-700">{error}</span>
//                         </small>
//                     </div> : null }
//                 <div className="text-center">
//                     <Button className="my-4" color="primary" type="button" onClick={sendEmail}>
//                         Reset Password
//                     </Button>
//                 </div>
//             </Form>
//         </CardBody>
//     </Card>
// </Col>
// </>
